import React, { FC, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
} from '@material-ui/core';
import styles from './BookingSystem.module.scss';
import { ArrowBack, Clear } from '@material-ui/icons';
import { ISession } from './BookingSystem';

interface BookingSystemConfirmationProps {
  selectedSession: ISession;
  onModalClose: () => void;
  onMakeBooking: (week: string, equipment: string) => void;
}

export const BookingSystemConfirmation: FC<BookingSystemConfirmationProps> = function ({
  selectedSession,
  onMakeBooking,
  onModalClose,
}) {
  const [registrationQuestionWeek, setRegistrationQuestionWeek] = useState<string>('');
  const [registrationQuestionEquipment, setRegistrationQuestionEquipment] = useState<string>('');
  const [termsCheckbox, setTermsCheckbox] = React.useState(false);

  return (
    <>
      <Modal
        className={styles.modal}
        open={!!selectedSession}
        onClose={onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={!!selectedSession}>
          <div className={styles.paper}>
            <IconButton aria-label="close" onClick={onModalClose} className={styles.closeButton}>
              <Clear />
            </IconButton>

            <h1 id="transition-modal-title" className={styles.paragraphHeading}>
              Book this session
            </h1>
            <FormControl component="fieldset" fullWidth={true}>
              {selectedSession.weekAndEquipmentRegistrationRequired && (
                <>
                  <Select
                    className={styles.select}
                    variant="outlined"
                    value={registrationQuestionWeek}
                    displayEmpty
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setRegistrationQuestionWeek(event.target.value as string);
                    }}>
                    <MenuItem value="" disabled>
                      <span className={styles.inputLabel}>
                        {' '}
                        Select week you'll be teaching this session
                      </span>
                    </MenuItem>

                    <MenuItem value="0">First Workshop</MenuItem>

                    {Array.from({ length: 20 }, (_, i) => i + 1).map((i) => (
                      <MenuItem key={i} value={i}>
                        Week {i}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>

            <FormControl component="fieldset" fullWidth={true}>
              {selectedSession.weekAndEquipmentRegistrationRequired && (
                <>
                  <Select
                    className={styles.select}
                    variant="outlined"
                    value={registrationQuestionEquipment}
                    displayEmpty
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setRegistrationQuestionEquipment(event.target.value as string);
                    }}>
                    <MenuItem value="" disabled>
                      <span className={styles.inputLabel}>
                        {' '}
                        Select equipment you will have available this session
                      </span>
                    </MenuItem>
                    <MenuItem value={'Mat'}>Mat only</MenuItem>
                    <MenuItem value={'Reformer'}>Reformer only</MenuItem>
                    <MenuItem value={'Mat/Reformer'}>Mat & Reformer</MenuItem>
                  </Select>
                </>
              )}
            </FormControl>
            <FormControl component="fieldset" fullWidth={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsCheckbox}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setTermsCheckbox(event.target.checked)
                    }
                    color={'primary'}
                    name="termsCheckbox"
                  />
                }
                label={
                  selectedSession.waitListAvailable
                    ? 'I understand that it is my responsibility to cancel workshops, or remove myself from the waitlist, at least 2 hours before the start time to avoid penalties.'
                    : 'I understand that it is my responsibility to cancel workshops at least 2 hours before the start time to avoid penalties.'
                }
              />

              <Box mt={4}>
                <Box component="span" mr={2}>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={onModalClose}
                    startIcon={<ArrowBack />}>
                    Back
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={
                    (selectedSession.weekAndEquipmentRegistrationRequired
                      ? !registrationQuestionWeek
                      : false) || !termsCheckbox
                  }
                  onClick={() => {
                    onMakeBooking(registrationQuestionWeek, registrationQuestionEquipment);
                  }}>
                  {selectedSession.slotsAvailable > 0 ? 'Complete booking' : 'Join Waitlist'}
                </Button>
              </Box>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
