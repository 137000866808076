import { TimezoneSelector } from 'views/components/TimezoneSelector/TimezoneSelector';
import styles from './BookClass.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  GridList,
  GridListTile,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useStore } from 'views/hooks';
import { useLocation, useParams } from 'react-router-dom';
import {
  Calendar,
  IAcuityAppointmentBookingResponse,
  IntakeAppointmentTypeModel,
  IntakeTutorialModel,
  IPracticeTeachingAppointment,
} from 'domain/store/BookingModel';
import moment from 'moment';
import _, { Dictionary } from 'lodash';
import { ArrowBack, ArrowForward, CheckCircle, Clear, Error, ExpandMore } from '@material-ui/icons';
import { getClassTypeLabel, StudentClassType } from 'views/public/StudentClasses/StudentClassType';
import TableContainer from '@material-ui/core/TableContainer/TableContainer';
import { titleCase } from 'title-case';
import { plural } from 'pluralize';
import { Alert } from '@material-ui/lab';
import PersonIcon from '@material-ui/icons/Person';
import ScheduleIcon from '@material-ui/icons/Schedule';
import FilterListIcon from '@material-ui/icons/FilterList';
import { PreRegParam } from '../Manage/ManageClass';

interface bookingError {
  errorTitle: string;
  errorDescription?: string;
}

interface bookingOutcome {
  appointmentTypeId: number;
  successful: boolean;
  alreadyExists: boolean;
  errorMessage?: string;
  bookingResponse: IAcuityAppointmentBookingResponse | null;
  dateTime: Date;
  calendar: string;
}

export const BookClass: React.FC<{ classType: StudentClassType }> = function ({ classType }) {
  const [showSpinner, setShowSpinner] = React.useState(false);
  const { categories } = useParams<{ categories: string }>();
  const queryStringParams = new URLSearchParams(useLocation().search);
  const genericClassName = titleCase(queryStringParams.get('classTypeName') ?? '');
  const preRegParams: PreRegParam[] = JSON.parse(queryStringParams.get('prereg') as string);
  const waitListEnabled = (queryStringParams.get('waitlist') as string) === 'true';
  const [registrationQuestionWeek, setRegistrationQuestionWeek] = useState<string>('');
  const [registrationQuestionEquipment, setRegistrationQuestionEquipment] = useState<string>('');
  const { bookingModel, defaultUserTimezone } = useStore();
  const [userTimezone, setUserTimezone] = useState<string>(defaultUserTimezone);
  const [appointmentTypes, setAppointmentTypes] = React.useState<IntakeAppointmentTypeModel[]>([]);
  const [classes, setClasses] = React.useState<IntakeTutorialModel[]>([]);
  const [trainerOrRoomClasses, setTrainerOrRoomClasses] = React.useState<
    Dictionary<IntakeTutorialModel[]>
  >({});
  const [trainers, setTrainers] = React.useState<string[]>([]);
  const [calendars, setCalendars] = React.useState<Calendar[]>([]);
  const [selectedTrainer, setSelectedTrainer] = React.useState('');
  const [selectedCalendarId, setSelectedCalendarId] = React.useState(-1);
  const [selectedAppointmentType, setSelectedAppointmentType] = React.useState('');
  const [selectedClassIdOrTime, setSelectedClassIdOrTime] = React.useState('');
  const anyAvailable = 'Any available';
  const accordionRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const bookButtonRef: React.RefObject<HTMLButtonElement> = React.useRef(null);
  const confirmationMessageRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [termsCheckbox, setTermsCheckbox] = React.useState(false);
  const [ptAllowSelfSignup, setPtAllowSelfSignup] = React.useState(true);
  const [sessionTopic, setSessionTopic] = React.useState('');
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [repeatModalOpen, setRepeatModalOpen] = React.useState(false);
  const [bookingResponse, setBookingResponse] = React.useState<
    IAcuityAppointmentBookingResponse & { bookingMade?: boolean | undefined }
  >();
  const [ptSelectedDate, setPTSelectedDate] = React.useState<moment.Moment>(moment());
  const numberOfDaysToShowAvailability = 5;
  const [error, setError] = React.useState<bookingError | null>(null);
  const [ptBookRoom, setPtBookRoom] = React.useState(false);
  const [existingPtSessions, setExistingPtSessions] = React.useState<
    IPracticeTeachingAppointment[]
  >([]);
  const [repeatBookings, setRepeatBookings] = React.useState<repeatBooking[]>([]);
  const [bookingOutcomes, setBookingOutcomes] = React.useState<bookingOutcome[]>([]);
  const [sessionTypes, setSessionTypes] = React.useState<string[]>([]);
  const [sessionTypeFilter, setSessionTypeFilter] = useState<string | null>(null);
  const [filterPopupEl, setFilterPopupEl] = React.useState<HTMLElement | null>(null);
  const [
    duplicateTutorialValidationTriggered,
    setDuplicateTutorialValidationTriggered,
  ] = React.useState<boolean>(false);
  const [
    duplicateBookingValidationTriggered,
    setDuplicateBookingValidationTriggered,
  ] = React.useState<boolean>(false);
  const [genericSessionTypeQueryParameter, setGenericSessionTypeQueryParameter] = React.useState<
    string[]
  >([]);
  const [bookingButtonText, setBookingButtonText] = React.useState('Book your spot');

  const classLabel =
    classType === StudentClassType.GenericClass || classType === StudentClassType.GenericRoom
      ? genericClassName
      : getClassTypeLabel(classType, false);
  const classLabelPlural =
    classType === StudentClassType.GenericClass || classType === StudentClassType.GenericRoom
      ? plural(genericClassName)
      : getClassTypeLabel(classType, true);

  useEffect(() => {
    const genericSessionTypeQueryParameter = titleCase(queryStringParams.get('sessionTypes') ?? '')
      .split(',')
      .map((t) => t.trim())
      .filter((t) => !!t);

    setGenericSessionTypeQueryParameter(genericSessionTypeQueryParameter);
    //eslint-disable-next-line
  }, []); // only ever want this to run once

  useEffect(() => {
    if (categories) {
      setShowSpinner(true);
      let sortedAppointmentTypes: IntakeAppointmentTypeModel[] = [];
      bookingModel.loadIntakeAppointmentTypes(categories).then(
        (appointmentTypes: IntakeAppointmentTypeModel[]) => {
          if (appointmentTypes) {
            const filteredAppointmentTypes =
              classType === StudentClassType.PracticeTeaching ||
              classType === StudentClassType.GenericRoom
                ? appointmentTypes
                : appointmentTypes.filter((a) => a.type === 'class');

            sortedAppointmentTypes = filteredAppointmentTypes.sort((a, b) =>
              a.name.localeCompare(b.name, 'en', { numeric: true })
            );
            setAppointmentTypes(sortedAppointmentTypes);
          }
          setShowSpinner(false);
        },
        (error) => {
          console.log(error);
          setShowSpinner(false);
        }
      );
    }
  }, [categories, bookingModel, classType]);

  useEffect(() => {
    if (
      (classType === StudentClassType.PracticeTeaching ||
        classType === StudentClassType.GenericRoom) &&
      appointmentTypes.length > 0
    ) {
      setShowSpinner(true);

      let promises: Promise<any>[] = [];

      appointmentTypes.forEach((appointmentType) => {
        promises.push(bookingModel.getPracticeTeachingSessionsOpenToSelfSignup(appointmentType.id));
      });

      Promise.all(promises).then(
        (allPromisesValues: IPracticeTeachingAppointment[][]) => {
          let selfSignUpSession = allPromisesValues.flat();

          let futurePtSessions = selfSignUpSession?.filter((t) => moment(t.dateTime) >= moment());

          if (genericSessionTypeQueryParameter.length === 0) {
            // would be nice if this wasn't hard coded like this. Somehow writing this comment makes it ok...
            if (classType === StudentClassType.GenericRoom && classLabel === 'Study Group') {
              futurePtSessions = futurePtSessions?.filter(
                (t) => t.practiceTeachingNotesObject.sessionType === 'Study Group Diploma'
              );
              setSessionTopic('Study Group Diploma'); // there's only a single option, so pre-select.
            } else if (classType === StudentClassType.PracticeTeaching) {
              futurePtSessions = futurePtSessions?.filter(
                (t) => t.practiceTeachingNotesObject.sessionType !== 'Study Group Diploma'
              );
            }
          } else {
            futurePtSessions = futurePtSessions.filter((t) =>
              genericSessionTypeQueryParameter.includes(t.practiceTeachingNotesObject.sessionType)
            );

            if (genericSessionTypeQueryParameter.length === 1) {
              setSessionTopic(genericSessionTypeQueryParameter[0]);
            }
          }

          const sortedSessions = _.sortBy(futurePtSessions, (s) =>
            moment(s.dateTime).tz(userTimezone)
          );

          setSessionTypes(
            _.uniq(sortedSessions.map((s) => s.practiceTeachingNotesObject.sessionType))
          );

          setExistingPtSessions(sortedSessions);
          setShowSpinner(false);
        },
        (error) => {
          console.log(error);
          setShowSpinner(false);
        }
      );
    }
  }, [
    appointmentTypes,
    bookingModel,
    classType,
    classLabel,
    userTimezone,
    genericSessionTypeQueryParameter,
  ]);

  const setGroupedClasses = useCallback(
    (newTimeZone: string, updatedClasses: IntakeTutorialModel[], trainer?: string) => {
      // show all classes when anyAvailable is selected, otherwise filter by the selected trainer
      const selectedClasses =
        trainer === anyAvailable ||
        classType === StudentClassType.PracticeTeaching ||
        classType === StudentClassType.GenericRoom
          ? updatedClasses
          : updatedClasses.filter((c) => c.calendar === trainer);

      // group by date so we can show group of times for each day
      let filteredAndSorted = _(selectedClasses)
        .filter((c) => moment(c.time) >= moment())
        .sortBy((a) => a.time);

      //There are overlapping calendars for PT, so we need to filter out duplicates
      if (
        classType === StudentClassType.PracticeTeaching ||
        classType === StudentClassType.GenericRoom
      ) {
        filteredAndSorted = filteredAndSorted.uniqBy((i) => i.time);
      }

      const grouped = filteredAndSorted
        .groupBy((c) => moment(c.time).tz(newTimeZone).format('dddd, MMMM Do YYYY'))
        .value();

      setTrainerOrRoomClasses(grouped);
    },
    [classType]
  );

  const loadClassesForAppointmentType = useCallback(
    (appointmentTypeId: any) => {
      setShowSpinner(true);
      setSelectedAppointmentType(appointmentTypeId as string);
      setSelectedTrainer('');
      setSelectedClassIdOrTime('');

      if (
        classType === StudentClassType.PracticeTeaching ||
        classType === StudentClassType.GenericRoom
      ) {
        const calendarIDs = appointmentTypes.find((t) => t.id === (appointmentTypeId as number))
          ?.calendarIDs as Array<number>;

        bookingModel.loadCalendars(calendarIDs.join(',')).then(
          (c: Calendar[]) => {
            setCalendars([{ id: 0, name: anyAvailable }, ...c]);
            setShowSpinner(false);
          },
          (error) => {
            console.log(error);
            setShowSpinner(false);
          }
        );
      } else {
        bookingModel.loadIntakeTutorialClasses(appointmentTypeId as string, userTimezone).then(
          (c: IntakeTutorialModel[]) => {
            setClasses(c);

            // anyAvailable will show all classes for all trainers
            let trainers = [anyAvailable];
            // remove duplicates in trainers
            trainers = trainers.concat(Array.from(new Set(c.map((t) => t.calendar))));

            setTrainers(trainers);
            setShowSpinner(false);
          },
          (error) => {
            console.log(error);
            setShowSpinner(false);
          }
        );
      }
    },
    [appointmentTypes, bookingModel, classType, userTimezone]
  );

  const handleAppointmentTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const appointmentTypeId = event.target.value;
    loadClassesForAppointmentType(appointmentTypeId);
  };

  const updateRoom = useCallback(
    (roomId: unknown, date: moment.Moment) => {
      const dateInTimezone = date.tz(userTimezone).toISOString();
      setShowSpinner(true);
      bookingModel
        .getAvailabilityDates(selectedAppointmentType, roomId as string, dateInTimezone)
        .then(
          (r) => {
            setClasses(r);
            setGroupedClasses(userTimezone, r);
            setSelectedCalendarId(roomId as number);
            setShowSpinner(false);

            setTimeout(() => {
              if (accordionRef.current) {
                accordionRef.current.scrollIntoView({ behavior: 'smooth' });
              }
            }, 200);
          },
          (error) => {
            console.log(error);
            setShowSpinner(false);
          }
        );
    },
    [bookingModel, selectedAppointmentType, userTimezone, setGroupedClasses]
  );

  // auto-select appointment type if there's only one
  useEffect(() => {
    // Generic case: if there's only a single appointment type for the given Appointment Category, just pre-select it
    if (appointmentTypes.length === 1) {
      loadClassesForAppointmentType(appointmentTypes[0].id);
    }

    //Special case for Diploma Study Groups because this whole feature was shoehorned to support it - fml
    if (
      classType === StudentClassType.GenericRoom &&
      classLabel === 'Study Group' &&
      genericSessionTypeQueryParameter.length === 0
    ) {
      const studyGroupAppointmentType = appointmentTypes.find((t) => t.name === 'Study Group');
      if (studyGroupAppointmentType) {
        loadClassesForAppointmentType(studyGroupAppointmentType.id);
      }
    }
  }, [
    appointmentTypes,
    loadClassesForAppointmentType,
    classLabel,
    classType,
    genericSessionTypeQueryParameter.length,
  ]);

  // For PT and other room bookings, no point letting them select the room, just select all/any
  useEffect(() => {
    if (
      classType === StudentClassType.PracticeTeaching ||
      classType === StudentClassType.GenericRoom
    ) {
      updateRoom(0, moment()); // 0 = all rooms/calendars
    }
  }, [calendars, updateRoom, classType]);

  const updateTrainer = (trainer: string, updatedClasses: IntakeTutorialModel[]) => {
    setGroupedClasses(userTimezone, updatedClasses, trainer);
    setSelectedTrainer(trainer);
    setSelectedClassIdOrTime('');

    setTimeout(() => {
      if (accordionRef.current) {
        accordionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  };

  const handleTrainerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const trainer = event.target.value as string;
    updateTrainer(trainer, classes);
  };

  const handleTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setExpanded(expanded);
    setSelectedClassIdOrTime(event.target.value as string);

    const selectedClassOrAppointment = classes.find(
      (c) => c.id.toString() === (event.target.value as string)
    );

    let bookButtonText = 'Book your spot';
    if (selectedClassOrAppointment?.slotsAvailable === 0 && waitListEnabled) {
      bookButtonText = 'Join Waitlist';
    }

    setBookingButtonText(bookButtonText);

    setTimeout(() => {
      if (bookButtonRef.current) {
        bookButtonRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  };

  const isClassSelected = (key: string, selectedClassIdOrTime: string) => {
    const condition =
      classType === StudentClassType.PracticeTeaching || classType === StudentClassType.GenericRoom
        ? (c: IntakeTutorialModel) => c.time.toString() === selectedClassIdOrTime
        : (c: IntakeTutorialModel) => c.id.toString() === selectedClassIdOrTime;

    return _.some(trainerOrRoomClasses[key], condition);
  };

  const handleUserTimeZoneChange = (newValue: string) => {
    if (newValue !== userTimezone) {
      setUserTimezone(newValue);
      setGroupedClasses(newValue, classes, selectedTrainer);
    }
  };

  const validateNoExistingBookingForThisTutorialWeek = (appointmentTypeId: number) => {
    return new Promise<boolean>((resolve, reject) => {
      bookingModel
        .loadStudentTutorialBookings(categories, queryStringParams.get('email') as string)
        .then((existingBookings: any[]) => {
          let alreadyBooked = _.some(existingBookings, (b) => {
            return (
              b.appointmentTypeID === (appointmentTypeId as number) &&
              (b.labels === null || b.labels.find((bb: any) => bb.name === 'Attended'))
            );
          });

          if (alreadyBooked) {
            setDuplicateTutorialValidationTriggered(true);
          }

          resolve(!alreadyBooked);
        })
        .catch((e) => {
          console.log("Unable to check if already booked for week's tutorial. " + e);
          resolve(true); // continue - not showstopper if unable to confirm
        });
    });
  };

  const makeBooking = () => {
    setShowSpinner(true);
    setConfirmModalOpen(false);

    const appointmentTypeModel = appointmentTypes.find(
      (t) => t.id === ((selectedAppointmentType as unknown) as number)
    );

    const selectedClassOrAppointment =
      classType === StudentClassType.PracticeTeaching || classType === StudentClassType.GenericRoom
        ? classes.find((c) => c.time.toString() === selectedClassIdOrTime)
        : classes.find((c) => c.id.toString() === selectedClassIdOrTime);

    var isJoiningWaitlist = selectedClassOrAppointment?.slotsAvailable === 0;

    let isTutorialBooking = classType === StudentClassType.Tutorial;

    let preBookingValidationForNonTutorialBookings = (
      appTypeId: number,
      time: Date,
      calendar: string,
      moment: any
    ) => {
      return new Promise<boolean>((resolve, reject) => {
        bookingModel
          .loadStudentTutorialBookings(categories, queryStringParams.get('email') as string)
          .then((existingBookings: any[]) => {
            let alreadyBooked = _.some(existingBookings, (b) => {
              return (
                b.appointmentTypeID === (appTypeId as number) &&
                moment(b.dateTime).tz(userTimezone).format() === time &&
                b.calendar === calendar
              );
            });

            if (alreadyBooked) {
              setDuplicateBookingValidationTriggered(true);
            }

            resolve(!alreadyBooked);
          })
          .catch((e) => {
            console.log('Unable to check if session already booked. ' + e);
            resolve(true); // continue - not showstopper if unable to confirm
          });
      });
    };

    const getBookingPromise: (
      appTypeId: number,
      time: Date,
      sendConfirmationEmail: boolean,
      calendar: string
    ) => Promise<bookingOutcome> = (
      appTypeId: number,
      time: Date,
      sendConfirmationEmail: boolean,
      calendar: string
    ) => {
      const preBookingValidation = isTutorialBooking
        ? validateNoExistingBookingForThisTutorialWeek(appTypeId)
        : preBookingValidationForNonTutorialBookings(appTypeId, time, calendar, moment);

      return preBookingValidation
        .then((validationPassed) => {
          if (validationPassed) {
            var preRegParam = preRegParams?.find((p) => p.appointmentTypeId === appTypeId);

            return bookingModel
              .postIntakeBookAppointment({
                appointmentTypeID: appTypeId,
                datetime: time,
                calendar: calendar,
                firstName: queryStringParams.get('firstname') as string,
                lastName: queryStringParams.get('lastname') as string,
                email: queryStringParams.get('email') as string,
                timezone: userTimezone,
                allowPeerSelfSignup: ptAllowSelfSignup as boolean,
                ptSessionType: sessionTopic as string,
                classType: getClassTypeLabel(classType, false),
                sendConfirmationEmail: sendConfirmationEmail,
                preRegisterParticipant: !!preRegParam,
                preRegisterQuestions: {
                  week: preRegParam?.preRegQuestions.some((q) => q === 'week')
                    ? registrationQuestionWeek.toString()
                    : '',
                  equipment: preRegParam?.preRegQuestions.some((q) => q === 'equipment')
                    ? registrationQuestionEquipment
                    : '',
                },
                isJoiningWaitlist: isJoiningWaitlist,
              })
              .then(
                (r) => {
                  return {
                    appointmentTypeId: appTypeId,
                    successful: !!r,
                    alreadyExists: false,
                    bookingResponse: r,
                    dateTime: time,
                    calendar: calendar,
                  };
                },
                (error) => {
                  console.log('Booking error: ' + JSON.stringify(error));
                  return {
                    appointmentTypeId: appTypeId,
                    successful: false,
                    alreadyExists: false,
                    bookingResponse: null,
                    dateTime: time,
                    calendar: calendar,
                  };
                }
              );
          }
          return {
            appointmentTypeId: appTypeId,
            successful: false,
            alreadyExists: true,
            bookingResponse: null,
            dateTime: time,
            calendar: calendar,
          };
        })
        .catch((e) => {
          console.log('Booking error occurred posting appointment to Acuity: ' + e);
          return {
            appointmentTypeId: appTypeId,
            successful: false,
            alreadyExists: false,
            bookingResponse: null,
            dateTime: time,
            calendar: calendar,
          };
        });
    };

    let promises: Promise<bookingOutcome>[] = [];
    promises.push(
      getBookingPromise(
        appointmentTypeModel?.id as number,
        selectedClassOrAppointment?.time as Date,
        true,
        selectedClassOrAppointment?.calendar as string
      )
    );
    if (repeatBookings?.length > 0) {
      repeatBookings.forEach((rb) => {
        if (rb.makeBooking) {
          const bookingPromise = getBookingPromise(
            rb.appointmentTypeID,
            rb.dateTime!,
            false,
            rb.calendar!
          );
          promises.push(bookingPromise);
        }
      });
    }

    Promise.all(promises)
      .then((bookingResponses: bookingOutcome[]) => {
        const countBookingsSuccessful = bookingResponses.filter((b) => b.successful).length;

        if (countBookingsSuccessful > 0) {
          const firstSuccessfulBooking = bookingResponses.filter((b) => b.successful)[0];
          setBookingResponse(firstSuccessfulBooking.bookingResponse!);
        } else {
          setError({
            errorTitle: 'Uh oh! There was an error while trying to make your booking.',
            errorDescription: 'Please try to book again or contact us if the problem persists.',
          });
        }
        setBookingOutcomes(bookingResponses);
      })
      .catch((e) => {
        console.log('Booking error: ' + JSON.stringify(error));
        setError({
          errorTitle: 'Uh oh! There was an error while trying to make your booking.',
          errorDescription: 'Please try to book again or contact us if the problem persists.',
        });
      })
      .finally(() => {
        setShowSpinner(false);
        setTimeout(() => {
          confirmationMessageRef.current &&
            confirmationMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      });
  };

  const handleSelfSignup = (appointment: IPracticeTeachingAppointment) => {
    let firstName = queryStringParams.get('firstname') as string;
    let lastName = queryStringParams.get('lastname') as string;
    let email = queryStringParams.get('email') as string;

    setShowSpinner(true);
    bookingModel
      .bookSelfSignupPracticeTeachingSession(
        appointment.id,
        email,
        firstName,
        lastName,
        userTimezone
      )
      .then(
        (r) => {
          setBookingResponse((appointment as unknown) as IAcuityAppointmentBookingResponse);
          setShowSpinner(false);

          setTimeout(() => {
            confirmationMessageRef.current &&
              confirmationMessageRef.current.scrollIntoView({ behavior: 'smooth' });
          }, 200);
        },
        (error) => {
          setError({
            errorTitle: 'Uh oh! There was an error while trying to make your booking.',
            errorDescription: 'Please try to book again or contact us if the problem persists.',
          });
          setShowSpinner(false);
          console.log('Booking error: ' + JSON.stringify(error));
          setTimeout(() => {
            confirmationMessageRef.current &&
              confirmationMessageRef.current.scrollIntoView({ behavior: 'smooth' });
          }, 200);
        }
      );
  };

  const getAvailabilityLabel = (classesOnTheDay: IntakeTutorialModel[]) => {
    // If it's a masterclass, there is no limit for the number of attendees
    if (classType === StudentClassType.Masterclass) {
      return classesOnTheDay.length > 1
        ? `${classesOnTheDay.length} Masterclasses available`
        : `1 Masterclass available`;
    }

    const numberOfClassesWithAvailableSpots = classesOnTheDay.filter((t) => t.slotsAvailable > 0)
      .length;
    const numberOfSpots = _.sumBy(classesOnTheDay, (t) => t.slotsAvailable);

    if (!numberOfClassesWithAvailableSpots) {
      return `No ${
        classType === StudentClassType.Tutorial ? 'tutorials' : 'sessions'
      } with spots left`;
    }

    return `${numberOfClassesWithAvailableSpots} ${
      classType === StudentClassType.Tutorial ? 'tutorial(s)' : 'session(s)'
    } with ${numberOfSpots} spot(s) left`;
  };

  interface repeatBooking {
    appointmentTypeID: number;
    appointmentTypeName: string;
    alreadyBooked?: boolean;
    isSameTrainer?: boolean;
    isFullyBooked?: boolean;
    isTimeChanged?: boolean;
    isAvailable?: boolean;
    dateTime?: Date; //identifies the class for the given AppointmentType (id) to be booked
    calendar?: string;
    makeBooking: boolean;
    targetedDateTime?: Date;
    targetedCalendar: string;
    bookingMade: boolean;
  }

  const sameDayOfWeekHourAndMinute = (date1: Date, date2: Date) => {
    return moment(date1).format('dddd HH:mm') === moment(date2).format('dddd HH:mm');
  };

  const sameDayOfWeekAndWithinAnHour = (date1: Date, date2: Date) => {
    const sameDayOfWeek = moment(date1).format('dddd') === moment(date2).format('dddd');

    let hours = moment(date2).hours();
    let minutes = moment(date2).minutes();

    let newDate2 = moment(date1);
    newDate2.hours(hours);
    newDate2.minutes(minutes);

    return (
      sameDayOfWeek &&
      moment(date1).isBetween(
        moment(newDate2).add(-1, 'hours').add(-1, 'minutes'),
        moment(newDate2).add(1, 'hours').add(1, 'minutes')
      ) &&
      moment(date1).format('HH:mm') !== moment(newDate2).format('HH:mm')
    );
  };

  const handleBookButtonClick = () => {
    setRepeatBookings([]);
    if (classType === StudentClassType.Tutorial) {
      setShowSpinner(true);

      //appointmentTypes - list of weeks
      //selectedClassOrAppointment?.time - selected appointment
      //classes.find((c) => c.id.toString() === selectedClassIdOrTime

      const selectedClassOrAppointment = classes.find(
        (c) => c.id.toString() === selectedClassIdOrTime
      );

      // get future tutorials
      const start =
        appointmentTypes.findIndex((a) => a.id === parseInt(selectedAppointmentType)) + 1;
      var futureAppointmentTypes = appointmentTypes.slice(start);

      // get existing bookings
      bookingModel
        .loadStudentTutorialBookings(categories, queryStringParams.get('email') as string)
        .then((existingBookings: any[]) => {
          let promises: Promise<repeatBooking>[] = [];

          // for each week
          futureAppointmentTypes.forEach((f) => {
            let repeatBooking: repeatBooking = {
              appointmentTypeID: f.id,
              appointmentTypeName: f.name,
              makeBooking: true,
              targetedCalendar: selectedClassOrAppointment!.calendar,
              targetedDateTime: selectedClassOrAppointment!.time,
              bookingMade: false,
            };

            repeatBooking.alreadyBooked = _.some(existingBookings, (b) => {
              return b.appointmentTypeID === (f.id as number);
            });

            let promise: Promise<repeatBooking> | undefined = undefined;

            if (!repeatBooking.alreadyBooked) {
              promise = bookingModel
                .loadIntakeTutorialClasses(f.id.toString(), userTimezone)
                .then((availableClasses: IntakeTutorialModel[]) => {
                  const matchingClasses = availableClasses.filter((c) =>
                    sameDayOfWeekHourAndMinute(c.time, selectedClassOrAppointment!.time)
                  );

                  const nearbyClasses = availableClasses.filter((c) =>
                    sameDayOfWeekAndWithinAnHour(c.time, selectedClassOrAppointment!.time)
                  );

                  // find the closest matching class with slots available. Preferably with same time and trainer.
                  let closestMatch: IntakeTutorialModel | undefined = undefined;

                  // Same time and Trainer
                  let sameTimeAndTrainer = matchingClasses.filter(
                    (c) =>
                      c.slotsAvailable > 0 && c.calendar === selectedClassOrAppointment!.calendar
                  );
                  if (sameTimeAndTrainer.length > 0) {
                    closestMatch = sameTimeAndTrainer[0];
                  }

                  // same time but different trainer
                  if (!closestMatch) {
                    let sameTimeDifferentTrainer = matchingClasses.filter(
                      (c) =>
                        c.slotsAvailable > 0 && c.calendar !== selectedClassOrAppointment!.calendar
                    );

                    if (sameTimeDifferentTrainer) {
                      closestMatch = sameTimeDifferentTrainer[0];
                    }
                  }

                  // nearby times
                  if (!closestMatch) {
                    // same trainer
                    let nearbySameTrainerClasses = nearbyClasses.filter(
                      (c) =>
                        c.slotsAvailable > 0 && c.calendar === selectedClassOrAppointment!.calendar
                    );
                    if (nearbySameTrainerClasses.length > 0) {
                      closestMatch = nearbySameTrainerClasses[0];
                    }

                    //different trainer
                    if (!closestMatch) {
                      let nearByAnyTrainerClasses = nearbyClasses.filter(
                        (c) => c.slotsAvailable > 0
                      );
                      if (nearByAnyTrainerClasses.length > 0) {
                        closestMatch = nearByAnyTrainerClasses[0];
                      }
                    }
                  }

                  repeatBooking.dateTime = closestMatch?.time;
                  repeatBooking.calendar = closestMatch?.calendar;
                  repeatBooking.isAvailable = !!closestMatch;
                  repeatBooking.isFullyBooked = !closestMatch;
                  repeatBooking.makeBooking = repeatBooking.isAvailable;
                  repeatBooking.isSameTrainer =
                    closestMatch && closestMatch?.calendar === selectedClassOrAppointment!.calendar;
                  repeatBooking.isTimeChanged =
                    closestMatch &&
                    (moment(closestMatch!.time).tz(userTimezone).hour() !==
                      moment(selectedClassOrAppointment!.time).tz(userTimezone).hour() ||
                      moment(closestMatch!.time).tz(userTimezone).minute() !==
                        moment(selectedClassOrAppointment!.time).tz(userTimezone).minute());

                  return repeatBooking;
                });
            } else {
              repeatBooking.alreadyBooked = true;
              repeatBooking.makeBooking = false;
              promise = Promise.resolve(repeatBooking);
            }

            promises.push(promise);
          }); // end foreach week

          Promise.all(promises)
            .then((rb) => {
              let repeatBookings: repeatBooking[] = [];
              repeatBookings.push(...rb);
              setRepeatBookings(repeatBookings);

              // if there are future repeat booking available
              if (repeatBookings.some((rb) => rb.isAvailable)) {
                setRepeatModalOpen(true);
              } else {
                setConfirmModalOpen(true);
              }
            })
            .finally(() => {
              //hide spinner
              setShowSpinner(false);
            });
        });
    } else {
      setConfirmModalOpen(true);
    }
    setTermsCheckbox(false);
  };

  return (
    <>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.container}>
        <div className={styles.paperContainer}>
          <div hidden={!!bookingResponse || !!error}>
            {(classType === StudentClassType.PracticeTeaching ||
              classType === StudentClassType.GenericRoom) &&
              !ptBookRoom && (
                <>
                  <div>&nbsp;</div>
                  <Alert severity={'info'}>
                    Discover and sign up to {classLabelPlural} created by your peers. Or,&nbsp;
                    <Button
                      className={styles.textButton}
                      variant="text"
                      color="secondary"
                      size="large"
                      onClick={() => {
                        setPtBookRoom(true);
                      }}>
                      book your own
                    </Button>{' '}
                    discoverable or private session.
                  </Alert>

                  {/* Existing Practice Teaching sessions available to join */}
                  {existingPtSessions && existingPtSessions.length > 0 && (
                    <>
                      <Box mt={5}>
                        <Box component="span">
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => {
                              window.history.back();
                            }}
                            startIcon={<ArrowBack />}>
                            Back
                          </Button>
                        </Box>
                        <Box component="span" ml={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => {
                              setPtBookRoom(true);
                            }}>
                            Book your own {classLabel}
                          </Button>
                        </Box>
                      </Box>
                      <TimezoneSelector onChange={handleUserTimeZoneChange} />

                      <TableContainer component={Paper}>
                        <Table className={styles.table} aria-label="simple table" size={'small'}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                What{' '}
                                {sessionTypes.length > 1 && (
                                  <>
                                    <Button
                                      className={styles.narrowButton}
                                      variant="text"
                                      color={sessionTypeFilter ? 'primary' : undefined}
                                      size="small"
                                      onClick={(event) => {
                                        setFilterPopupEl(event.currentTarget);
                                      }}>
                                      <FilterListIcon />
                                    </Button>
                                    <Popover
                                      open={Boolean(filterPopupEl)}
                                      anchorEl={filterPopupEl}
                                      onClose={() => {
                                        setFilterPopupEl(null);
                                      }}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}>
                                      <div className={styles.popover}>
                                        <Select
                                          variant="outlined"
                                          value={sessionTypeFilter ?? ''}
                                          displayEmpty
                                          onChange={(
                                            event: React.ChangeEvent<{ value: unknown }>
                                          ) => {
                                            setSessionTypeFilter(event.target.value as string);
                                            setFilterPopupEl(null);
                                          }}>
                                          <MenuItem value="">All</MenuItem>
                                          {sessionTypes.map((s, i) => (
                                            <MenuItem key={i} value={s}>
                                              {s}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </div>
                                    </Popover>
                                  </>
                                )}
                              </TableCell>
                              <TableCell>When</TableCell>
                              <TableCell>Who</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {existingPtSessions
                              .filter(
                                (s) =>
                                  !sessionTypeFilter ||
                                  s.practiceTeachingNotesObject.sessionType === sessionTypeFilter
                              )
                              .map((s) => (
                                <TableRow key={s.id}>
                                  <TableCell component="th" scope="row">
                                    {s.practiceTeachingNotesObject.sessionType}
                                  </TableCell>
                                  <TableCell>
                                    {moment(s.dateTime)
                                      .tz(userTimezone)
                                      .format('DD MMM YYYY h:mm a')}
                                  </TableCell>
                                  <TableCell>
                                    {titleCase(s.practiceTeachingNotesObject.initiator.firstName)}{' '}
                                    {titleCase(s.practiceTeachingNotesObject.initiator.lastName)}
                                    {s.practiceTeachingNotesObject.participants.length > 0
                                      ? ', ' +
                                        s.practiceTeachingNotesObject.participants
                                          .map(
                                            (p) =>
                                              titleCase(p.firstName) + ' ' + titleCase(p.lastName)
                                          )
                                          .join(', ')
                                      : ''}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      disabled={
                                        s.practiceTeachingNotesObject.initiator.email ===
                                          queryStringParams.get('email') ||
                                        _(s.practiceTeachingNotesObject.participants).some(
                                          (p) => p.email === queryStringParams.get('email')
                                        ) ||
                                        (s.practiceTeachingNotesObject.participants &&
                                          s.practiceTeachingNotesObject.participants.length >= 4)
                                      }
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() => handleSelfSignup(s)}>
                                      {s.practiceTeachingNotesObject.initiator.email ===
                                      queryStringParams.get('email')
                                        ? 'Your session'
                                        : _(s.practiceTeachingNotesObject.participants).some(
                                            (p) => p.email === queryStringParams.get('email')
                                          )
                                        ? 'Already signed up'
                                        : s.practiceTeachingNotesObject.participants &&
                                          s.practiceTeachingNotesObject.participants.length >= 4
                                        ? 'Fully booked'
                                        : 'Sign up to ' +
                                          titleCase(
                                            s.practiceTeachingNotesObject.initiator.firstName
                                          ) +
                                          "'s session"}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}

                  {existingPtSessions && existingPtSessions.length === 0 && (
                    <p>
                      <i>
                        There are no existing {classLabelPlural} currently available for you to
                        join.
                      </i>
                    </p>
                  )}

                  <Box mt={5}>
                    <Box component="span">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => {
                          window.history.back();
                        }}
                        startIcon={<ArrowBack />}>
                        Back
                      </Button>
                    </Box>
                    <Box component="span" ml={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => {
                          setPtBookRoom(true);
                        }}>
                        Book your own {classLabel}
                      </Button>
                    </Box>
                  </Box>
                </>
              )}

            {((classType !== StudentClassType.PracticeTeaching &&
              classType !== StudentClassType.GenericRoom) ||
              ptBookRoom) && (
              <>
                {/* PT and other Generic Room bookings no longer require the selection of AppointmentType or Room */}
                {classType !== StudentClassType.PracticeTeaching &&
                  classType !== StudentClassType.GenericRoom && (
                    <>
                      {/* Tutorial week select control */}
                      <div className={styles.formDiv}>
                        {appointmentTypes && appointmentTypes.length > 0 && (
                          <FormControl className={styles.formControl}>
                            <span className={styles.inputLabel}>I would like to schedule...</span>
                            <Select
                              variant="outlined"
                              value={selectedAppointmentType}
                              displayEmpty
                              onChange={handleAppointmentTypeChange}>
                              <MenuItem value="" disabled>
                                <span className={styles.inputLabel}>{`Select ${
                                  classType === StudentClassType.Masterclass
                                    ? 'Masterclass'
                                    : classType === StudentClassType.Tutorial
                                    ? 'Tutorial Week'
                                    : classLabel
                                }`}</span>
                              </MenuItem>
                              {appointmentTypes.map((r, i) => (
                                <MenuItem key={i} value={r.id}>
                                  <div>
                                    <label>
                                      <span>
                                        {r.name} ({r.duration} minutes)
                                      </span>
                                    </label>
                                  </div>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </div>

                      {/* Trainer select input */}
                      <div className={styles.formDiv}>
                        {selectedAppointmentType && (
                          <FormControl className={styles.formControl}>
                            <span className={styles.inputLabel}>with...</span>
                            <Select
                              variant="outlined"
                              value={selectedTrainer}
                              displayEmpty
                              onChange={handleTrainerChange}>
                              <MenuItem value="" disabled>
                                <span className={styles.inputLabel}> Select Trainer</span>
                              </MenuItem>
                              {trainers.map((r, i) => (
                                <MenuItem key={i} value={r}>
                                  <div>
                                    <label>
                                      <span>{r}</span>
                                    </label>
                                  </div>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </div>
                    </>
                  )}

                {/* Classes date and time availability */}
                {selectedAppointmentType && selectedTrainer && (
                  <>
                    <Box mt={6} mb={2}>
                      <span className={styles.inputLabel}>Choose a date and time...</span>
                    </Box>
                    <div style={{ border: 'ridge' }} ref={accordionRef}>
                      <Box ml={8}>
                        <TimezoneSelector onChange={handleUserTimeZoneChange} />
                      </Box>
                      <Box m={2}></Box>
                      <Box className={styles.root}>
                        <RadioGroup
                          aria-label="time"
                          name="time"
                          value={selectedClassIdOrTime}
                          onChange={handleTimeChange}>
                          {!trainerOrRoomClasses ||
                          Object.keys(trainerOrRoomClasses).length === 0 ? (
                            <Box component="span" ml={8} mb={4}>
                              {/* Check if all classes are in the past or there are no classes at all */}
                              {_(classes)
                                .filter(
                                  (c) =>
                                    selectedTrainer === anyAvailable ||
                                    c.calendar === selectedTrainer
                                )
                                .value()
                                .every((c) => moment(c.time) < moment())
                                ? `All ${classLabelPlural} for the selected week are in the past`
                                : 'No times available for the selected week'}
                            </Box>
                          ) : (
                            Object.keys(trainerOrRoomClasses).map((k, i) => (
                              <Accordion
                                key={'accordion' + i}
                                expanded={expanded === 'panel' + i}
                                onChange={(event: React.ChangeEvent<{}>, isExpanded: boolean) =>
                                  setExpanded(isExpanded ? 'panel' + i : false)
                                }
                                className={
                                  isClassSelected(k, selectedClassIdOrTime)
                                    ? styles.selectedTime
                                    : ''
                                }>
                                <AccordionSummary
                                  key={'accordionSummary' + i}
                                  expandIcon={<ExpandMore />}
                                  aria-controls={'panel' + i}
                                  id={'panel' + i + '-header'}>
                                  <Typography key={'typography1' + i} className={styles.heading}>
                                    {' '}
                                    {k}{' '}
                                  </Typography>
                                  <Typography
                                    key={'Typography2' + i}
                                    className={styles.secondaryHeading}>
                                    {getAvailabilityLabel(trainerOrRoomClasses[k])}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  key={'accordionDetails' + i}
                                  className={styles.accordionDetails}>
                                  {trainerOrRoomClasses[k].map((v, i) => (
                                    <FormControlLabel
                                      key={'formControlLabel' + i}
                                      value={v.id.toString()}
                                      control={<Radio color={'primary'} />}
                                      disabled={v.slotsAvailable === 0 && !waitListEnabled}
                                      label={
                                        <>
                                          <span>
                                            {moment(v.time).tz(userTimezone).format('h:mm a')}
                                          </span>
                                          {classType !== StudentClassType.Masterclass &&
                                            (v.slotsAvailable === 0 ? (
                                              <>
                                                {!waitListEnabled && (
                                                  <Chip
                                                    className={styles.chip}
                                                    size="small"
                                                    label="FULLY BOOKED"
                                                  />
                                                )}
                                                {waitListEnabled && (
                                                  <Chip
                                                    className={styles.chip}
                                                    size="small"
                                                    label="JOIN WAITLIST"
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <Chip
                                                className={styles.chip}
                                                color="primary"
                                                size="small"
                                                label={`${v.slotsAvailable} spot${
                                                  v.slotsAvailable > 1 ? 's' : ''
                                                } left`}
                                              />
                                            ))}
                                          {selectedTrainer === anyAvailable ? (
                                            <Chip
                                              className={styles.chip}
                                              size="small"
                                              label={v.calendar}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      }
                                    />
                                  ))}
                                </AccordionDetails>
                              </Accordion>
                            ))
                          )}
                        </RadioGroup>
                      </Box>
                    </div>
                  </>
                )}

                {/* PT sessions date and time availability */}
                {selectedAppointmentType && selectedCalendarId !== -1 && (
                  <>
                    <Box mt={6} mb={2}>
                      <span className={styles.inputLabel}>Choose a date and time...</span>
                    </Box>
                    <div style={{ border: 'ridge' }} ref={accordionRef}>
                      <Box ml={8}>
                        <TimezoneSelector onChange={handleUserTimeZoneChange} />
                      </Box>
                      <Box m={2}>
                        <Button
                          className={styles.earlierDatesButton}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            updateRoom(
                              selectedCalendarId,
                              ptSelectedDate.add(-numberOfDaysToShowAvailability, 'days')
                            );
                            setPTSelectedDate(ptSelectedDate);
                          }}
                          startIcon={<ArrowBack />}
                          disabled={ptSelectedDate < moment().add(-5)}>
                          {'Previous Dates'}
                        </Button>
                        <Button
                          className={styles.laterDatesButton}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            updateRoom(
                              selectedCalendarId,
                              ptSelectedDate.add(numberOfDaysToShowAvailability, 'days')
                            );
                            setPTSelectedDate(ptSelectedDate);
                          }}
                          endIcon={<ArrowForward />}>
                          {'More Dates'}
                        </Button>
                      </Box>
                      <Box className={styles.root} style={{ display: 'inline-flex' }}>
                        <RadioGroup
                          aria-label="time"
                          name="time"
                          value={selectedClassIdOrTime}
                          onChange={handleTimeChange}>
                          {Object.keys(trainerOrRoomClasses).map((k, i) => (
                            <Accordion
                              key={'accordion' + i}
                              expanded={expanded === 'panel' + i}
                              onChange={(event: React.ChangeEvent<{}>, isExpanded: boolean) =>
                                setExpanded(isExpanded ? 'panel' + i : false)
                              }
                              className={
                                isClassSelected(k, selectedClassIdOrTime) ? styles.selectedTime : ''
                              }>
                              <AccordionSummary
                                key={'accordionSummary' + i}
                                expandIcon={<ExpandMore />}
                                aria-controls={'panel' + i}
                                id={'panel' + i + '-header'}>
                                <Typography key={'typography1' + i} className={styles.heading}>
                                  {' '}
                                  {k}{' '}
                                </Typography>
                                <Typography
                                  key={'Typography2' + i}
                                  className={styles.secondaryHeading}>
                                  {getAvailabilityLabel(trainerOrRoomClasses[k])}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails
                                key={'accordionDetails' + i}
                                className={styles.accordionDetails}>
                                <GridList cellHeight={50}>
                                  {trainerOrRoomClasses[k].map((v, i) => (
                                    <GridListTile key={'glt' + i}>
                                      <FormControlLabel
                                        style={{ marginLeft: '0px' }}
                                        key={'formControlLabel' + i}
                                        value={v.time.toString()}
                                        control={<Radio />}
                                        disabled={v.slotsAvailable === 0}
                                        label={
                                          <span>
                                            {moment(v.time).tz(userTimezone).format('h:mm a')}
                                          </span>
                                        }
                                      />
                                    </GridListTile>
                                  ))}
                                </GridList>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </RadioGroup>
                      </Box>
                    </div>
                  </>
                )}

                {/* Booking and cancel buttons */}
                <Box mt={5}>
                  <Box component="span">
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      ref={bookButtonRef}
                      onClick={() => {
                        window.history.back();
                      }}
                      startIcon={<ArrowBack />}>
                      Back
                    </Button>
                  </Box>
                  <Box component="span" hidden={!selectedClassIdOrTime} ml={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      ref={bookButtonRef}
                      onClick={handleBookButtonClick}>
                      {bookingButtonText}
                    </Button>
                  </Box>
                </Box>
              </>
            )}

            {/* Booking confirmation modal */}
            {selectedClassIdOrTime && (
              <>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={styles.modal}
                  open={confirmModalOpen}
                  onClose={() => setConfirmModalOpen(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}>
                  <Fade in={confirmModalOpen}>
                    <div className={styles.paper}>
                      <Box width="5%" ml="auto">
                        <IconButton aria-label="close" onClick={() => setConfirmModalOpen(false)}>
                          <Clear />
                        </IconButton>
                      </Box>

                      <FormControl component="fieldset">
                        {(classType === StudentClassType.PracticeTeaching ||
                          classType === StudentClassType.GenericRoom) && (
                          <>
                            <h2 id="transition-modal-title" className={styles.paragraphHeading}>
                              {classLabel} Details
                            </h2>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color={'primary'}
                                  checked={ptAllowSelfSignup}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setPtAllowSelfSignup(event.target.checked)
                                  }
                                  name="ptAllowSelfSignup"
                                />
                              }
                              label="Allow your peers to discover and sign up to this session. You will be notified when they do."
                            />

                            {ptAllowSelfSignup && (
                              <>
                                <Select
                                  variant="outlined"
                                  value={sessionTopic}
                                  displayEmpty
                                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                    setSessionTopic(event.target.value as string);
                                  }}>
                                  <MenuItem value="" disabled>
                                    <span className={styles.inputLabel}> Select Session Type</span>
                                  </MenuItem>

                                  {genericSessionTypeQueryParameter.length === 0 &&
                                    classType === StudentClassType.PracticeTeaching && (
                                      <MenuItem value={'Matwork PT'}>Matwork PT</MenuItem>
                                    )}
                                  {genericSessionTypeQueryParameter.length === 0 &&
                                    classType === StudentClassType.PracticeTeaching && (
                                      <MenuItem value={'Reformer PT'}>Reformer PT</MenuItem>
                                    )}
                                  {genericSessionTypeQueryParameter.length === 0 &&
                                    classType === StudentClassType.PracticeTeaching && (
                                      <MenuItem value={'Comprehensive PT'}>
                                        Comprehensive PT
                                      </MenuItem>
                                    )}
                                  {genericSessionTypeQueryParameter.length === 0 &&
                                    classType === StudentClassType.PracticeTeaching && (
                                      <MenuItem value={'Study Group Certificate'}>
                                        Study Group Certificate
                                      </MenuItem>
                                    )}
                                  {genericSessionTypeQueryParameter.length === 0 &&
                                    classType === StudentClassType.GenericRoom &&
                                    classLabel === 'Study Group' && (
                                      <MenuItem value={'Study Group Diploma'}>
                                        Study Group Diploma
                                      </MenuItem>
                                    )}

                                  {genericSessionTypeQueryParameter.length > 0 &&
                                    genericSessionTypeQueryParameter.map((s, k) => (
                                      <MenuItem key={k} value={s}>
                                        {s}
                                      </MenuItem>
                                    ))}
                                </Select>
                                <div>&nbsp;</div>
                              </>
                            )}
                          </>
                        )}

                        {preRegParams &&
                          preRegParams?.find(
                            (p) => p.appointmentTypeId === parseInt(selectedAppointmentType)
                          ) && (
                            <>
                              <h2 id="transition-modal-title" className={styles.paragraphHeading}>
                                Registration Details
                              </h2>
                              {preRegParams &&
                                preRegParams
                                  .find(
                                    (p) => p.appointmentTypeId === parseInt(selectedAppointmentType)
                                  )
                                  ?.preRegQuestions.some((q) => q === 'week') && (
                                  <>
                                    <Select
                                      className={styles.select}
                                      variant="outlined"
                                      value={registrationQuestionWeek}
                                      displayEmpty
                                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                        setRegistrationQuestionWeek(event.target.value as string);
                                      }}>
                                      <MenuItem value="" disabled>
                                        <span className={styles.inputLabel}>
                                          {' '}
                                          Select week you'll be teaching this session
                                        </span>
                                      </MenuItem>

                                      <MenuItem value="0">First Workshop</MenuItem>

                                      {Array.from({ length: 20 }, (_, i) => i + 1).map((i) => (
                                        <MenuItem key={i} value={i}>
                                          Week {i}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </>
                                )}
                              {preRegParams &&
                                preRegParams
                                  .find(
                                    (p) => p.appointmentTypeId === parseInt(selectedAppointmentType)
                                  )
                                  ?.preRegQuestions.find((q) => q === 'equipment') && (
                                  <>
                                    <Select
                                      className={styles.select}
                                      variant="outlined"
                                      value={registrationQuestionEquipment}
                                      displayEmpty
                                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                        setRegistrationQuestionEquipment(
                                          event.target.value as string
                                        );
                                      }}>
                                      <MenuItem value="" disabled>
                                        <span className={styles.inputLabel}>
                                          {' '}
                                          Select equipment you will have available this session
                                        </span>
                                      </MenuItem>

                                      <MenuItem value={'Mat'}>Mat only</MenuItem>
                                      <MenuItem value={'Reformer'}>Reformer only</MenuItem>
                                      <MenuItem value={'Mat/Reformer'}>Mat & Reformer</MenuItem>
                                    </Select>
                                  </>
                                )}
                            </>
                          )}

                        <h2 id="transition-modal-title" className={styles.paragraphHeading}>
                          Attendance &amp; Participation
                        </h2>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={termsCheckbox}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setTermsCheckbox(event.target.checked)
                              }
                              color={'primary'}
                              name="termsCheckbox"
                            />
                          }
                          label={
                            waitListEnabled
                              ? 'I understand that it is my responsibility to cancel workshops, or remove myself from the waitlist, at least 2 hours before the start time to avoid penalties.'
                              : 'I confirm that I agree to the Code of Conduct and Waiver of Liability, and will be 5 minutes early for my booked session.'
                          }
                        />
                        {!waitListEnabled && (
                          <Box ml={4}>
                            <div>
                              <a
                                href="https://breathe-education.com/terms-of-service/#codeofconduct"
                                target="_blank"
                                rel="noreferrer">
                                Code of Conduct
                              </a>
                            </div>
                          </Box>
                        )}

                        <Box mt={4}>
                          <Box component="span" mr={2}>
                            <Button
                              variant="contained"
                              size="medium"
                              onClick={() => setConfirmModalOpen(false)}
                              startIcon={<ArrowBack />}>
                              Back
                            </Button>
                          </Box>
                          <Button
                            variant="contained"
                            size="medium"
                            disabled={
                              (preRegParams
                                ?.find(
                                  (p) => p.appointmentTypeId === parseInt(selectedAppointmentType)
                                )
                                ?.preRegQuestions.some((q) => q === 'week')
                                ? !registrationQuestionWeek
                                : false) ||
                              (preRegParams
                                ?.find(
                                  (p) => p.appointmentTypeId === parseInt(selectedAppointmentType)
                                )
                                ?.preRegQuestions.some((q) => q === 'equipment')
                                ? !registrationQuestionEquipment
                                : false) ||
                              classType === StudentClassType.PracticeTeaching
                                ? !(termsCheckbox && (!ptAllowSelfSignup || sessionTopic))
                                : !termsCheckbox
                            }
                            onClick={makeBooking}>
                            {bookingButtonText === 'Book your spot'
                              ? 'Complete booking'
                              : 'Join Waitlist'}
                          </Button>
                        </Box>
                      </FormControl>
                    </div>
                  </Fade>
                </Modal>
              </>
            )}

            {/* Repeat booking modal */}
            {selectedClassIdOrTime && (
              <>
                <Modal
                  aria-labelledby="repeat-booking-modal-title"
                  className={styles.modal}
                  open={repeatModalOpen}
                  onClose={() => setRepeatModalOpen(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}>
                  <Fade in={repeatModalOpen}>
                    <div className={styles.paper}>
                      <Box width="5%" ml="auto">
                        <IconButton aria-label="close" onClick={() => setRepeatModalOpen(false)}>
                          <Clear />
                        </IconButton>
                      </Box>
                      <h2 id="repeat-booking-modal-title" className={styles.paragraphHeading}>
                        Would you like to also book the same (or similar) time for the weeks after?
                      </h2>
                      <FormControl component="fieldset">
                        <FormGroup row={true} className={styles.repeatBookings}>
                          {repeatBookings.map((rb, i) => (
                            <>
                              <FormControlLabel
                                key={i}
                                className={styles.checkboxRow}
                                control={
                                  <Checkbox
                                    checked={rb.makeBooking}
                                    disabled={rb.alreadyBooked || !rb.isAvailable}
                                    onChange={() => {
                                      let repeatBookingsCopy = [...repeatBookings];
                                      repeatBookingsCopy[i].makeBooking = !repeatBookingsCopy[i]
                                        .makeBooking;
                                      setRepeatBookings(repeatBookingsCopy);
                                    }}
                                    name="gilad"
                                  />
                                }
                                label={
                                  <>
                                    {rb.appointmentTypeName +
                                      ' - ' +
                                      (!rb.alreadyBooked && rb.isAvailable
                                        ? moment(rb.dateTime).tz(userTimezone).format('L LT') +
                                          ' with ' +
                                          (rb.calendar ?? 'TBC')
                                        : rb.alreadyBooked
                                        ? 'Already booked'
                                        : moment(rb.targetedDateTime)
                                            .tz(userTimezone)
                                            .format('ddd LT') +
                                          ' with ' +
                                          rb.targetedCalendar +
                                          ' - Not available')}
                                    {!rb.isSameTrainer && rb.isAvailable && (
                                      <Tooltip title={'Different trainer'}>
                                        <PersonIcon></PersonIcon>
                                      </Tooltip>
                                    )}
                                    {rb.isTimeChanged && rb.isAvailable && (
                                      <Tooltip title={'Time change in your selected timezone'}>
                                        <ScheduleIcon></ScheduleIcon>
                                      </Tooltip>
                                    )}
                                  </>
                                }
                              />
                            </>
                          ))}
                        </FormGroup>
                        <FormControl component="fieldset">
                          <Box mt={2}>
                            <Box component="span" mr={1}>
                              <Button
                                className={styles.button}
                                variant="contained"
                                color="primary"
                                size="medium"
                                onClick={() => setRepeatModalOpen(false)}
                                startIcon={<ArrowBack />}>
                                Back
                              </Button>
                            </Box>
                            <Button
                              className={styles.button}
                              variant="contained"
                              color="primary"
                              size="medium"
                              onClick={() => {
                                setRepeatBookings([]);
                                setRepeatModalOpen(false);
                                setConfirmModalOpen(true);
                              }}>
                              Skip
                            </Button>
                            <Button
                              className={styles.button}
                              variant="contained"
                              color="primary"
                              size="medium"
                              onClick={() => {
                                setRepeatModalOpen(false);
                                setConfirmModalOpen(true);
                              }}>
                              Yes ({repeatBookings.filter((rb) => rb.makeBooking).length} weeks
                              selected)
                            </Button>
                          </Box>
                        </FormControl>
                      </FormControl>
                    </div>
                  </Fade>
                </Modal>
              </>
            )}
          </div>

          <div ref={confirmationMessageRef}>
            {/* Booking success message */}
            <Box
              hidden={!bookingResponse}
              mt={4}
              pt={4}
              pb={4}
              mr="auto"
              ml="auto"
              width="60%"
              className={styles.confirmationMessage}>
              {/*Just single booking, no repeat bookings*/}
              {(!repeatBookings ||
                repeatBookings.length === 0 ||
                repeatBookings.filter((rb) => rb.makeBooking).length === 0) && (
                <>
                  <h2 className={`${styles.paragraphHeading} ${styles.h2ConfirmationMessage}`}>
                    <CheckCircle className={styles.successIcon} fontSize="large" />
                    {bookingResponse?.alreadyOnWaitlist && (
                      <span>
                        You're already on the waitlist. You're #
                        {bookingResponse.waitlistQueuePosition} in the queue.
                      </span>
                    )}
                    {bookingResponse?.joinedWaitlist && (
                      <span>
                        You've joined the waitlist. You're #{bookingResponse.waitlistQueuePosition}{' '}
                        in the queue.
                      </span>
                    )}
                    {!bookingResponse?.alreadyOnWaitlist && !bookingResponse?.joinedWaitlist && (
                      <span> Your {classLabel} has been booked!</span>
                    )}
                  </h2>
                  {!bookingResponse?.alreadyOnWaitlist && !bookingResponse?.joinedWaitlist && (
                    <div>
                      <p>
                        <strong>{`${getClassTypeLabel(classType, false)} time`}</strong>
                      </p>
                      <p>
                        {moment(bookingResponse?.dateTime)
                          .tz(userTimezone)
                          .format('dddd, MMMM Do YYYY h:mm a')}
                      </p>
                      <br />

                      <div
                        hidden={
                          classType === StudentClassType.PracticeTeaching ||
                          classType === StudentClassType.GenericRoom
                        }>
                        <p>
                          <strong>Trainer</strong>
                        </p>
                        <p>{bookingResponse?.calendar}</p>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/*Main booking + repeat bookings*/}

              {repeatBookings && repeatBookings.filter((rb) => rb.makeBooking).length > 0 && (
                <>
                  <h2 className={`${styles.paragraphHeading} ${styles.h2ConfirmationMessage}`}>
                    <CheckCircle className={styles.successIcon} fontSize="large" />
                    <span> The following {classLabelPlural} have been booked!</span>
                  </h2>

                  {bookingOutcomes
                    .filter((bo) => bo.successful)
                    .map((bo, i) => (
                      <div key={i}>
                        <p>
                          {moment(bo.dateTime).tz(userTimezone).format('dddd, MMMM Do YYYY h:mm a')}{' '}
                          with {bo.calendar}
                        </p>
                      </div>
                    ))}

                  {bookingOutcomes.filter((bo) => !bo.successful).length > 0 && (
                    <p>
                      <strong>The following bookings were not successful:</strong>
                    </p>
                  )}

                  {bookingOutcomes
                    .filter((bo) => !bo.successful)
                    .map((bo, i) => (
                      <div key={i}>
                        <p>
                          {moment(bo.dateTime).tz(userTimezone).format('dddd, MMMM Do YYYY h:mm a')}{' '}
                          with {bo.calendar} {bo.alreadyExists ? ' (already booked)' : ''}
                        </p>
                      </div>
                    ))}
                  {duplicateTutorialValidationTriggered && (
                    <p>
                      Please note: only a single pending tutorial booking is allowed for each week.
                      To change your booking please cancel the existing booking.
                    </p>
                  )}
                  {duplicateBookingValidationTriggered && (
                    <p>Please note: you already have a booking for this time.</p>
                  )}
                </>
              )}

              <Box mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    window.location.assign(
                      `/public/intake/${
                        classType === StudentClassType.Masterclass
                          ? 'masterclasses'
                          : classType === StudentClassType.Tutorial
                          ? 'tutorials'
                          : classType === StudentClassType.PracticeTeaching
                          ? 'pt'
                          : classType === StudentClassType.GenericClass
                          ? 'class'
                          : 'room'
                      }/students/manage/${categories}?${queryStringParams.toString()}`
                    )
                  }>
                  {`Back to My ${classLabelPlural}`}
                </Button>
              </Box>
            </Box>

            {/* Booking error message */}
            <Box
              hidden={!error}
              mt={4}
              pt={4}
              pb={4}
              mr="auto"
              ml="auto"
              width="60%"
              className={styles.confirmationMessage}>
              <div>
                {duplicateTutorialValidationTriggered && (
                  <>
                    <h2 className={`${styles.paragraphHeading} ${styles.h2ConfirmationMessage}`}>
                      <Error className={styles.errorIcon} fontSize="large" />
                      <span> Uh oh! A booking for this week already exists.</span>
                    </h2>
                    <p>Please cancel your existing booking before proceeding.</p>
                  </>
                )}

                {duplicateBookingValidationTriggered && (
                  <>
                    <h2 className={`${styles.paragraphHeading} ${styles.h2ConfirmationMessage}`}>
                      <Error className={styles.errorIcon} fontSize="large" />
                      <span> Uh oh! A booking for this time already exists.</span>
                    </h2>
                    <p>Please cancel your existing booking before proceeding.</p>
                  </>
                )}

                {!duplicateTutorialValidationTriggered && !duplicateBookingValidationTriggered && (
                  <>
                    <h2 className={`${styles.paragraphHeading} ${styles.h2ConfirmationMessage}`}>
                      <Error className={styles.errorIcon} fontSize="large" />
                      <span> {error?.errorTitle}</span>
                    </h2>
                    <p>{error?.errorDescription}</p>
                  </>
                )}
              </div>
              <Box mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => window.history.back()}>
                  {`Back to My ${classLabelPlural}`}
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
