import React, { useEffect, useRef } from 'react';
import styles from './ManageClass.module.scss';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Tab,
  Tabs,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { useStore } from 'views/hooks';
import { IAppointment } from 'domain/store/BookingModel';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { TimezoneSelector } from 'views/components/TimezoneSelector/TimezoneSelector';
import { Alert } from '@material-ui/lab';
import { ClassList, IClassListPeriod } from './ClassList';
import _ from 'lodash';
import { getClassTypeLabel, StudentClassType } from 'views/public/StudentClasses/StudentClassType';
import { plural } from 'pluralize';
import { titleCase } from 'title-case';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ArrowForward from '@material-ui/icons/ArrowForward';

export interface PreRegParam {
  appointmentTypeId: number;
  preRegQuestions: string[];
}

export const ManageClass: React.FC<{ classType: StudentClassType }> = function ({ classType }) {
  const { categories } = useParams<{ categories: string }>();
  const { bookingModel, defaultUserTimezone } = useStore();
  const [appointments, setAppointments] = React.useState<IAppointment[]>([]);

  const queryStringParams = new URLSearchParams(useLocation().search);
  const email = queryStringParams.get('email') as string;
  const firstName = queryStringParams.get('firstname') as string;
  const lastName = queryStringParams.get('lastname') as string;
  const genericClassName = titleCase(queryStringParams.get('classTypeName') ?? '');
  const preRegParams: PreRegParam[] = JSON.parse(queryStringParams.get('prereg') as string);

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [
    selectedAppointmentToCancel,
    setSelectedAppointmentToCancel,
  ] = React.useState<IAppointment>();
  const [
    selectedAppointmentToChange,
    setSelectedAppointmentToChange,
  ] = React.useState<IAppointment>();
  const [selectedAppointment, setSelectedAppointment] = React.useState<IAppointment>();
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
  const [openChangeModal, setOpenChangeModal] = React.useState(false);
  const [userTimezone, setUserTimezone] = React.useState<string>(defaultUserTimezone);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [showCustomErrorMessage, setCustomShowErrorMessage] = React.useState(false);
  const [customErrorMessage, setCustomErrorMessage] = React.useState(
    'Uh oh! Something went wrong. Please try again later.'
  );
  const [
    updateRegistrationQuestionWeek,
    setUpdateRegistrationQuestionWeek,
  ] = React.useState<string>('');
  const [
    updateRegistrationQuestionEquipment,
    setUpdateRegistrationQuestionEquipment,
  ] = React.useState<string>('');
  const pageSize = 11;

  const preRegParamsRef = useRef(preRegParams);
  preRegParamsRef.current = preRegParams;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setPage(1);
  };

  const handleUserTimeZoneChange = (newValue: string) => {
    setUserTimezone(newValue);
  };

  useEffect(() => {
    setShowSpinner(true);
    bookingModel
      .loadStudentTutorialBookings(
        categories,
        email,
        getClassTypeLabel(classType, false),
        preRegParamsRef.current?.map((p) => p.appointmentTypeId)
      )
      .then(
        (a: IAppointment[]) => {
          setAppointments(_.sortBy(a, (a) => a.dateTime));
          setShowSpinner(false);
        },
        (error) => {
          console.log(error);
          setShowSpinner(false);
        }
      );
  }, [email, categories, bookingModel, classType]);

  const cancelAppointment = (appointmentId: string) => {
    setOpenCancelDialog(false);
    setShowSpinner(true);

    bookingModel.cancelAppointment(appointmentId).then(
      () => {
        setAppointments(appointments.filter((i) => i.id !== selectedAppointmentToCancel?.id));
        setShowSpinner(false);
        setShowSuccessMessage(true);
      },
      (error) => {
        console.log(error);
        setShowSpinner(false);
        setShowErrorMessage(true);
      }
    );
  };

  const leaveAppointment = (appointmentId: string) => {
    setOpenCancelDialog(false);
    setShowSpinner(true);

    bookingModel
      .cancelSelfSignupPracticeTeachingSession(
        appointmentId,
        email,
        firstName,
        lastName,
        userTimezone
      )
      .then(
        () => {
          setAppointments(appointments.filter((i) => i.id !== selectedAppointmentToCancel?.id));
          setShowSpinner(false);
          setShowSuccessMessage(true);
        },
        (error) => {
          console.log(error);
          setShowSpinner(false);
          setShowErrorMessage(true);
        }
      );
  };

  const updateAppointment = () => {
    if (!selectedAppointmentToChange) return;

    setShowSpinner(true);
    setOpenChangeModal(false);

    var updatedAppointment = _.cloneDeep(selectedAppointmentToChange);

    //update custom questions

    var weekQuestion = updatedAppointment.customQuestions?.find((q) => q.title === 'Week');
    var equipmentQuestion = updatedAppointment.customQuestions?.find(
      (q) => q.title === 'Equipment'
    );

    if (weekQuestion && updateRegistrationQuestionWeek)
      weekQuestion.value = updateRegistrationQuestionWeek.toString() ?? weekQuestion.value;
    if (equipmentQuestion && updateRegistrationQuestionEquipment)
      equipmentQuestion.value = updateRegistrationQuestionEquipment ?? equipmentQuestion.value;

    console.log('Week:', weekQuestion?.value);
    console.log('Equipment:', equipmentQuestion?.value);

    bookingModel
      .updateAppointment(updatedAppointment)
      .then(
        (updatedAppointment) => {
          if (updatedAppointment) {
            var index = appointments.findIndex((a) => a.id === selectedAppointmentToChange.id);
            appointments[index] = updatedAppointment;
            setAppointments(appointments);
            setShowSuccessMessage(true);
          } else {
            setCustomErrorMessage('Update failed. Zoom only allows 3 updates per day.');
            setCustomShowErrorMessage(true);
          }
        },
        (error) => {
          console.log(error);
          setCustomErrorMessage(error);
          setCustomShowErrorMessage(true);
        }
      )
      .finally(() => {
        setOpenChangeModal(false);
        setShowSpinner(false);
        setUpdateRegistrationQuestionWeek('');
        setUpdateRegistrationQuestionEquipment('');
      });
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}>
        {value === index && <Box p={2}>{children}</Box>}
      </div>
    );
  }

  const classLabel =
    classType === StudentClassType.GenericClass || classType === StudentClassType.GenericRoom
      ? genericClassName
      : getClassTypeLabel(classType, false);
  const classLabelPlural =
    classType === StudentClassType.GenericClass || classType === StudentClassType.GenericRoom
      ? plural(genericClassName)
      : getClassTypeLabel(classType, true);

  if (email === null || firstName === null || lastName === null || email === '{{email}}')
    return <p>This page has not loaded correctly. Please try again or contact support.</p>;

  return (
    <>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.container}>
        <div className={styles.paperContainer}>
          <Box mt={5} mb={2}>
            <Box mt={3}>
              <Button
                className={styles.bookNewTutorialButton}
                variant="contained"
                color="secondary"
                size="small"
                href={`/public/intake/${
                  classType === StudentClassType.Masterclass
                    ? 'masterclasses'
                    : classType === StudentClassType.Tutorial
                    ? 'tutorials'
                    : classType === StudentClassType.PracticeTeaching
                    ? 'pt'
                    : classType === StudentClassType.GenericRoom
                    ? 'room'
                    : 'class'
                }/students/book/${categories}?${queryStringParams.toString()}`}
                startIcon={undefined}
                endIcon={<ArrowForward />}>
                {`Book ${classLabelPlural} here`}
              </Button>

              {classType === StudentClassType.PracticeTeaching && (
                <div>
                  <Accordion className={styles.accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={styles.accordionSummary}>
                      <InfoIcon /> &nbsp;&nbsp; Example Liability Waiver
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        Here is an{' '}
                        <a
                          href={
                            'https://be-certiv-2023-docs.s3.amazonaws.com/Liability%20form%20example.pdf'
                          }
                          target={'_blank'}
                          rel="noreferrer">
                          Example Liability Waiver
                        </a>{' '}
                        to adjust for your own purposes. Although injuries and accidents while
                        teaching are rare, we encourage you to consider obtaining liability
                        insurance and utilizing a liability waiver whenever you are instructing in
                        your own home/for your own business.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </Box>
          </Box>
          <Box>
            <TimezoneSelector onChange={handleUserTimeZoneChange} />
            <Box>
              <List className={styles.classList}>
                <Paper square>
                  <Tabs
                    className={styles.tabs}
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="upcoming and past">
                    <Tab label="Upcoming"></Tab>
                    <Tab label="Past"></Tab>
                  </Tabs>
                </Paper>
                {Object.values(IClassListPeriod).map((period, i) => (
                  <TabPanel key={i} value={tabValue} index={period}>
                    <ClassList
                      appointments={appointments}
                      currentPage={page}
                      onAppointmentSelect={(appointment: IAppointment) => {
                        setSelectedAppointment(appointment);
                        setOpenDetailsModal(true);
                      }}
                      onAppointmentCancel={(appointment: IAppointment) => {
                        setSelectedAppointmentToCancel(appointment);
                        setOpenCancelDialog(true);
                      }}
                      onAppointmentChange={(appointment: IAppointment) => {
                        setSelectedAppointmentToChange(appointment);
                        setOpenChangeModal(true);
                      }}
                      onPageChange={handlePageChange}
                      userTimezone={userTimezone}
                      period={(period as unknown) as IClassListPeriod}
                      pageSize={pageSize}
                      urlRegex={urlRegex}
                      classType={classType}
                      email={email}
                    />
                  </TabPanel>
                ))}
              </List>
            </Box>
          </Box>
        </div>
        <Dialog
          open={openCancelDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {selectedAppointmentToCancel?.isOnWaitlist && (
              <>
                {`Are you sure you want to remove yourself from the waitlist for this ${classLabel}?`}
              </>
            )}
            {!selectedAppointmentToCancel?.isOnWaitlist && (
              <>
                {`Are you sure you want to ${
                  selectedAppointmentToCancel?.email === email ? 'cancel' : 'leave'
                } this ${classLabel} booking?`}
              </>
            )}
          </DialogTitle>
          <DialogContent>
            <div>
              <p
                hidden={
                  classType === StudentClassType.PracticeTeaching ||
                  classType === StudentClassType.GenericRoom
                }>
                <span className={styles.detailsLable}>
                  {classType === StudentClassType.Masterclass ? 'Masterclass' : 'Description'}:
                </span>{' '}
                {selectedAppointmentToCancel?.type}
              </p>
              <p
                hidden={
                  classType === StudentClassType.PracticeTeaching ||
                  classType === StudentClassType.GenericRoom
                }>
                <span className={styles.detailsLable}>Trainer: </span>{' '}
                {selectedAppointmentToCancel?.calendar}
              </p>
              <p>
                <span className={styles.detailsLable}>Time: </span>
                {moment(selectedAppointmentToCancel?.dateTime)
                  .tz(userTimezone)
                  .format('dddd, MMMM Do YYYY h:mm a')}
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className={styles.confirmCancelButton}
              color="primary"
              autoFocus
              onClick={() => setOpenCancelDialog(false)}>
              Back
            </Button>
            <Button
              color="primary"
              className={styles.confirmCancelButton}
              onClick={() => {
                if (selectedAppointmentToCancel) {
                  if (selectedAppointmentToCancel?.email === email) {
                    cancelAppointment(
                      selectedAppointmentToCancel.id
                        ? selectedAppointmentToCancel.id.toString()
                        : 'WaitlistId:' + selectedAppointmentToCancel.waitlistId
                    );
                  } else {
                    leaveAppointment(selectedAppointmentToCancel.id.toString());
                  }
                }
              }}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          className={styles.modal}
          open={openChangeModal}
          onClose={() => {
            setOpenChangeModal(false);
            setUpdateRegistrationQuestionWeek('');
            setUpdateRegistrationQuestionEquipment('');
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={openChangeModal}>
            <div className={styles.paper}>
              <Box width="10%" ml="auto">
                <IconButton aria-label="close" onClick={() => setOpenChangeModal(false)}>
                  <Clear />
                </IconButton>
              </Box>
              <Box mb={2} ml={2} mr={2}>
                <h2 className={styles.paragraphHeading}>Change booking details</h2>

                <FormControl component="fieldset">
                  {selectedAppointmentToChange?.customQuestions?.find(
                    (q) => q.title === 'Week'
                  ) && (
                    <FormGroup className={styles.formControl}>
                      <FormControlLabel
                        control={<span></span>}
                        label={"Select the week you'll be teaching this session"}
                      />
                      <FormControl>
                        <Select
                          className={styles.select}
                          variant="outlined"
                          fullWidth={true}
                          value={
                            updateRegistrationQuestionWeek ||
                            selectedAppointmentToChange?.customQuestions?.find(
                              (q) => q.title === 'Week'
                            )?.value
                          }
                          displayEmpty
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setUpdateRegistrationQuestionWeek(event.target.value as string);
                          }}>
                          <MenuItem value="" disabled>
                            <span className={styles.inputLabel}>
                              {' '}
                              Select the week you'll be teaching this session
                            </span>
                          </MenuItem>

                          <MenuItem value="0">First Workshop</MenuItem>

                          {Array.from({ length: 20 }, (_, i) => i + 1).map((i) => (
                            <MenuItem key={i} value={i}>
                              Week {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  )}

                  {selectedAppointmentToChange?.customQuestions?.find(
                    (q) => q.title === 'Equipment'
                  ) && (
                    <FormGroup className={styles.formControl}>
                      <FormControlLabel
                        control={<span></span>}
                        label={'Select the equipment you will have available this session'}
                      />
                      <FormControl>
                        <Select
                          className={styles.select}
                          variant="outlined"
                          value={
                            updateRegistrationQuestionEquipment ||
                            selectedAppointmentToChange?.customQuestions?.find(
                              (q) => q.title === 'Equipment'
                            )?.value
                          }
                          displayEmpty
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setUpdateRegistrationQuestionEquipment(event.target.value as string);
                          }}>
                          <MenuItem value="" disabled>
                            <span className={styles.inputLabel}>
                              {' '}
                              Select the equipment you will have available this session
                            </span>
                          </MenuItem>

                          <MenuItem value={'Mat'}>Mat only</MenuItem>
                          <MenuItem value={'Reformer'}>Reformer only</MenuItem>
                          <MenuItem value={'Mat/Reformer'}>Mat & Reformer</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>
                  )}
                </FormControl>

                <div>
                  <Button
                    className={styles.confirmCancelButton}
                    color="primary"
                    autoFocus
                    onClick={() => {
                      setOpenChangeModal(false);
                      setUpdateRegistrationQuestionWeek('');
                      setUpdateRegistrationQuestionEquipment('');
                    }}>
                    Back
                  </Button>
                  <Button
                    color="primary"
                    disabled={
                      (!updateRegistrationQuestionWeek ||
                        updateRegistrationQuestionWeek ===
                          selectedAppointmentToChange?.customQuestions?.find(
                            (q) => q.title === 'Week'
                          )?.value) &&
                      (!updateRegistrationQuestionEquipment ||
                        updateRegistrationQuestionEquipment ===
                          selectedAppointmentToChange?.customQuestions?.find(
                            (q) => q.title === 'Equipment'
                          )?.value)
                    }
                    className={styles.confirmCancelButton}
                    onClick={() => updateAppointment()}>
                    Update
                  </Button>
                </div>
              </Box>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="tutorial-details-title"
          aria-describedby="tutorial-details-description"
          className={styles.modal}
          open={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={openDetailsModal}>
            <div className={styles.paper}>
              <Box width="10%" ml="auto">
                <IconButton aria-label="close" onClick={() => setOpenDetailsModal(false)}>
                  <Clear />
                </IconButton>
              </Box>
              <Box mb={2} ml={2} mr={2}>
                <h2 className={styles.paragraphHeading}>{`${classLabel} Details`}</h2>
                <div
                  hidden={
                    classType === StudentClassType.PracticeTeaching ||
                    classType === StudentClassType.GenericRoom
                  }>
                  <span className={styles.detailsLable}>
                    {classType === StudentClassType.Masterclass ? 'Masterclass' : 'Week'}:{' '}
                  </span>
                  {selectedAppointment?.type}
                </div>
                <div
                  hidden={
                    classType === StudentClassType.Masterclass ||
                    classType === StudentClassType.PracticeTeaching ||
                    classType === StudentClassType.GenericRoom
                  }>
                  <span className={styles.detailsLable}>Intake: </span>{' '}
                  {selectedAppointment?.category}
                </div>
                <div
                  hidden={
                    classType === StudentClassType.PracticeTeaching ||
                    classType === StudentClassType.GenericRoom
                  }>
                  <span className={styles.detailsLable}>Trainer: </span>
                  {selectedAppointment?.calendar}
                </div>
                <div>
                  <span className={styles.detailsLable}>Time: </span>
                  {moment(selectedAppointment?.dateTime)
                    .tz(userTimezone)
                    .format('dddd, MMMM Do YYYY h:mm a')}
                </div>
                <div hidden={classType === StudentClassType.Masterclass}>
                  <span className={styles.detailsLable}>Booked on: </span>
                  {moment(selectedAppointment?.dateTimeCreated)
                    .tz(userTimezone)
                    .format('dddd, MMMM Do YYYY h:mm a')}
                </div>
                <div>
                  <span className={styles.detailsLable}>Duration: </span>
                  {selectedAppointment?.duration} minutes
                </div>
                <div
                  // adding 2 hours (120 minutes) as buffer
                  hidden={
                    moment(selectedAppointment?.dateTime).add(
                      (selectedAppointment?.duration as number) + 120,
                      'minutes'
                    ) < moment()
                  }>
                  <Button
                    style={{ marginTop: '2em' }}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      if (
                        !preRegParams.some(
                          (p) => p.appointmentTypeId === selectedAppointment?.appointmentTypeID
                        )
                      )
                        window.open(selectedAppointment?.location.match(urlRegex)?.pop(), '_blank');
                      else window.open(selectedAppointment?.location, '_blank');
                    }}>
                    Join Live
                  </Button>
                  <div
                    className={styles.copyToClipboardLink}
                    hidden={
                      classType !== StudentClassType.PracticeTeaching &&
                      classType !== StudentClassType.GenericRoom
                    }>
                    <span className={styles.detailsLable}>Copy and share this link:</span>
                    <br />
                    <span className={styles.linkText}>
                      {selectedAppointment?.location.match(urlRegex)?.pop() ?? ''}
                    </span>
                  </div>
                </div>
              </Box>
            </div>
          </Fade>
        </Modal>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSuccessMessage}
          onClose={() => setShowSuccessMessage(false)}
          autoHideDuration={5000}>
          <Alert severity="success">{`Your ${classLabel} booking has been cancelled.`}</Alert>
        </Snackbar>
        <Snackbar
          open={showErrorMessage}
          onClose={() => setShowErrorMessage(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={5000}>
          <Alert severity="error">
            {` uh oh! There has been an error while trying to cancel the ${classLabel}.`}
          </Alert>
        </Snackbar>
        <Snackbar
          open={showCustomErrorMessage}
          onClose={() => setCustomShowErrorMessage(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={5000}>
          <Alert severity="error">{customErrorMessage}</Alert>
        </Snackbar>
      </div>
    </>
  );
};
