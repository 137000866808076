import React, { useEffect } from 'react';
import styles from './ClassList.module.scss';
import {
  IAppointment,
  IPracticeTeachingAppointment,
  IPracticeTeachingNotesObject,
} from 'domain/store/BookingModel';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import _ from 'lodash';
import {
  Box,
  Button,
  Chip,
  Divider,
  Link,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { getClassTypeLabel, StudentClassType } from '../StudentClassType';
import { titleCase } from 'title-case';
import camelcaseKeys from 'camelcase-keys';
import { useLocation } from 'react-router-dom';

export enum IClassListPeriod {
  Upcoming,
  Past,
}

interface IClassListProps {
  appointments: IAppointment[];
  currentPage: number;
  onAppointmentSelect: (appointment: IAppointment) => void;
  onAppointmentCancel: (appointment: IAppointment) => void;
  onAppointmentChange: (appointment: IAppointment) => void;
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  userTimezone: string;
  period: IClassListPeriod;
  pageSize: number;
  urlRegex: RegExp | null;
  classType: StudentClassType;
  email: string;
}

export const ClassList: React.FC<IClassListProps> = ({
  appointments,
  currentPage,
  onAppointmentSelect,
  onAppointmentCancel,
  onAppointmentChange,
  onPageChange,
  userTimezone,
  period,
  pageSize,
  urlRegex,
  classType,
  email,
}) => {
  const [appointmentsList, setAppointmentList] = React.useState<
    (IAppointment | IPracticeTeachingAppointment)[]
  >([]);
  const queryStringParams = new URLSearchParams(useLocation().search);
  const genericClassName = titleCase(queryStringParams.get('classTypeName') ?? '');

  useEffect(() => {
    let detailedAppointments: (IAppointment | IPracticeTeachingAppointment)[] = [];

    appointments.forEach((appointment) => {
      if (
        (classType === StudentClassType.PracticeTeaching ||
          classType === StudentClassType.GenericRoom) &&
        appointment.notes
      ) {
        const notes: IPracticeTeachingNotesObject = camelcaseKeys(JSON.parse(appointment.notes), {
          deep: true,
        });
        let practiceTeachingAppointment: IPracticeTeachingAppointment = {
          ...appointment,
          practiceTeachingNotesObject: notes,
        };

        detailedAppointments.push(practiceTeachingAppointment);
      } else {
        detailedAppointments.push(appointment);
      }
    });

    setAppointmentList(detailedAppointments);
  }, [appointments, classType]);

  const timeBuffer = 120; // 2 hours buffer
  const filteredAppointments = appointmentsList?.filter((t) =>
    // check if the session has ended (compare against session finish time)
    period === IClassListPeriod.Upcoming
      ? moment(t.dateTime).add(t.duration + timeBuffer, 'minutes') >= moment()
      : moment(t.dateTime).add(t.duration + timeBuffer, 'minutes') < moment()
  );

  function instanceOfIPracticeTeachingAppointment(
    object: any
  ): object is IPracticeTeachingAppointment {
    return 'practiceTeachingNotesObject' in object;
  }

  const pagedAppointments = _(filteredAppointments)
    .drop((currentPage - 1) * pageSize)
    .take(pageSize)
    .value();

  const classLabel =
    classType === StudentClassType.GenericClass || classType === StudentClassType.GenericRoom
      ? genericClassName
      : getClassTypeLabel(classType, false);

  return (
    <div className={styles.container}>
      {/* show nothing when there are no appointments */}
      {!pagedAppointments || pagedAppointments.length === 0 ? (
        <span>
          {`You have no ${period === IClassListPeriod.Past ? 'past ' : ''} ${classLabel} bookings`}
        </span>
      ) : (
        pagedAppointments.map((t, i) => (
          <div key={i}>
            <ListItem
              role={undefined}
              dense
              button
              onClick={(e) => !t.isOnWaitlist && onAppointmentSelect(t)}>
              <Box p={2} width={'70%'} className={styles.box}>
                <ListItemText>
                  <strong>{t.type}</strong>{' '}
                  <span>
                    {classType === StudentClassType.PracticeTeaching ||
                    classType === StudentClassType.GenericRoom
                      ? 'in'
                      : 'with'}
                  </span>{' '}
                  <Chip size="small" color="default" label={t.calendar} /> <span> on </span>
                  <Chip
                    color="default"
                    size="small"
                    label={moment(t.dateTime).tz(userTimezone).format('dddd, MMMM Do YYYY')}
                  />
                  <span> at </span>
                  <Chip
                    size="small"
                    color="default"
                    label={moment(t.dateTime).tz(userTimezone).format('h:mm a')}
                  />
                  {t.customQuestions && (
                    <>
                      <br />
                      <span>
                        Week:{' '}
                        <strong>
                          {t.customQuestions.filter((c) => c.title === 'Week')[0]?.value === '0'
                            ? 'First Workshop'
                            : t.customQuestions.filter((c) => c.title === 'Week')[0]?.value}
                        </strong>{' '}
                        Equipment:{' '}
                        <strong>
                          {t.customQuestions.filter((c) => c.title === 'Equipment')[0]?.value}
                        </strong>
                      </span>
                    </>
                  )}
                  {t.isOnWaitlist && (
                    <>
                      <br />
                      <span>
                        You are{' '}
                        <Chip
                          size="small"
                          color="default"
                          label={'#' + t.positionOnWaitlistQueue}
                        />{' '}
                        in the waitlist queue
                      </span>
                    </>
                  )}
                  {instanceOfIPracticeTeachingAppointment(t) &&
                    t.practiceTeachingNotesObject.sessionType && (
                      <>
                        <br />
                        Session topic:{' '}
                        <Chip
                          size="small"
                          color="default"
                          label={t.practiceTeachingNotesObject.sessionType}
                        />{' '}
                        {t.email === email &&
                          t.practiceTeachingNotesObject.participants &&
                          t.practiceTeachingNotesObject.participants.length > 0 && (
                            <>
                              with{' '}
                              {t.practiceTeachingNotesObject.participants
                                .map((p, i) => titleCase(p.firstName) + ' ' + titleCase(p.lastName))
                                .join(', ')}
                              <br />
                            </>
                          )}
                        {t.email !== email && (
                          <>
                            <br />
                            Organised by{' '}
                            <Chip
                              size="small"
                              color="default"
                              label={titleCase(t.firstName) + ' ' + titleCase(t.lastName)}
                            />{' '}
                            {t.practiceTeachingNotesObject.participants &&
                              t.practiceTeachingNotesObject.participants.length > 1 && (
                                <>
                                  with{' '}
                                  {t.practiceTeachingNotesObject.participants
                                    .filter((p) => p.email !== email)
                                    .map(
                                      (p, i) => titleCase(p.firstName) + ' ' + titleCase(p.lastName)
                                    )
                                    .join(', ')}
                                </>
                              )}{' '}
                          </>
                        )}
                      </>
                    )}
                </ListItemText>
              </Box>
              {period === IClassListPeriod.Upcoming && (
                <>
                  <ListItemSecondaryAction className={styles.secondaryActionButtons}>
                    {t.isOnWaitlist && (
                      <>
                        {moment(t.dateTime) > moment() && (
                          <Button
                            style={{ height: '2em' }}
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onAppointmentCancel(t);
                            }}>
                            Leave Waitlist
                          </Button>
                        )}
                      </>
                    )}

                    {!t.isOnWaitlist && (
                      <>
                        {moment(t.dateTime) > moment() && (
                          <Button
                            style={{ height: '2em' }}
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onAppointmentCancel(t);
                            }}>
                            {t.email !== email ? 'Leave Group' : 'Cancel'}
                          </Button>
                        )}
                        <Button
                          style={{ height: '2em' }}
                          variant="contained"
                          className={styles.joinLiveButton}
                          size="small"
                          onClick={() => {
                            if (urlRegex) window.open(t.location.match(urlRegex!)?.pop(), '_blank');
                            else window.open(t.location, '_blank');
                          }}>
                          Join Live
                        </Button>
                        <div
                          className={styles.copyToClipboardLink}
                          hidden={
                            classType !== StudentClassType.PracticeTeaching &&
                            classType !== StudentClassType.GenericRoom
                          }>
                          <Link
                            color={'primary'}
                            title="Copy to share"
                            onClick={() => onAppointmentSelect(t)}>
                            Click here to copy link
                          </Link>
                        </div>
                      </>
                    )}
                    {t.customQuestions && moment(t.dateTime) > moment() && (
                      <div>
                        <Button
                          style={{ height: '2em' }}
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onAppointmentChange(t);
                          }}>
                          Change
                        </Button>
                      </div>
                    )}
                  </ListItemSecondaryAction>
                </>
              )}
            </ListItem>
            <Divider></Divider>
          </div>
        ))
      )}
      {/* hide pagination when there is only one page or there are appointments to show */}
      <Box m={4} hidden={!filteredAppointments || filteredAppointments.length <= pageSize}>
        <Pagination
          className={styles.pagination}
          count={Math.ceil(filteredAppointments?.length / pageSize)}
          page={currentPage}
          onChange={onPageChange}
          color="secondary"
        />
      </Box>
    </div>
  );
};
