import React, { FC, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Snackbar,
} from '@material-ui/core';
import styles from './BookingSystem.module.scss';
import { ArrowBack, Clear, Delete, Save, Videocam } from '@material-ui/icons';
import { ISession } from './BookingSystem';
import { IAppointment } from '../../../domain/store/BookingModel';
import moment from 'moment-timezone';
import { useStore } from '../../hooks';
import _ from 'lodash';
import { Alert } from '@material-ui/lab';

interface BookingSystemBookedSessionModalProps {
  selectedSession: ISession;
  selectedAppointment: IAppointment;
  onModalClose: () => void;
  onUpdate: (success: boolean) => void;
  onCancel: () => void;
  userTimezone: string;
}

export const BookingSystemBookedSessionModal: FC<BookingSystemBookedSessionModalProps> = function ({
  selectedSession,
  onUpdate,
  onCancel,
  onModalClose,
  selectedAppointment,
  userTimezone,
}) {
  const { bookingModel } = useStore();
  const [registrationQuestionWeek, setRegistrationQuestionWeek] = useState<string>('');
  const [registrationQuestionEquipment, setRegistrationQuestionEquipment] = useState<string>('');
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [showCustomErrorMessage, setCustomShowErrorMessage] = React.useState<string | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<string | null>(null);

  const bookingWeek =
    selectedAppointment.customQuestions?.filter((q) => q.title === 'Week')[0].value ?? '';
  const bookingEquipment =
    selectedAppointment.customQuestions?.filter((q) => q.title === 'Equipment')[0].value ?? '';

  const bookingRequiresWeekAndEquipmentRegistration =
    selectedSession.weekAndEquipmentRegistrationRequired;

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  useEffect(() => {
    if (selectedSession.weekAndEquipmentRegistrationRequired) {
      setRegistrationQuestionWeek(
        selectedAppointment.customQuestions?.filter((q) => q.title === 'Week')[0].value ?? ''
      );
      setRegistrationQuestionEquipment(
        selectedAppointment.customQuestions?.filter((q) => q.title === 'Equipment')[0].value ?? ''
      );
    }
  }, [selectedAppointment, selectedSession]);

  const cancelAppointment = () => {
    setOpenCancelDialog(false);
    setShowSpinner(true);

    const appointmentId = selectedAppointment.id
      ? selectedAppointment.id.toString()
      : 'WaitlistId:' + selectedAppointment.waitlistId;

    bookingModel.cancelAppointment(appointmentId).then(
      () => {
        onCancel();
      },
      (error) => {
        setShowSpinner(false);
      }
    );
  };

  const updateAppointment = () => {
    if (!selectedAppointment) return;

    setShowSpinner(true);

    var updatedAppointment = _.cloneDeep(selectedAppointment);

    //update custom questions

    var weekQuestion = updatedAppointment.customQuestions?.find((q) => q.title === 'Week');
    var equipmentQuestion = updatedAppointment.customQuestions?.find(
      (q) => q.title === 'Equipment'
    );

    if (weekQuestion && registrationQuestionWeek)
      weekQuestion.value = registrationQuestionWeek.toString() ?? weekQuestion.value;
    if (equipmentQuestion && registrationQuestionEquipment)
      equipmentQuestion.value = registrationQuestionEquipment ?? equipmentQuestion.value;

    bookingModel
      .updateAppointment(updatedAppointment)
      .then(
        (updatedAppointment) => {
          if (updatedAppointment) {
            selectedAppointment = updatedAppointment;
            setShowSuccessMessage('Your booking has been updated.');
            onUpdate(true);
          } else {
            setCustomShowErrorMessage('Update failed. Zoom only allows 3 updates per day.');
            setRegistrationQuestionEquipment(bookingEquipment);
            setRegistrationQuestionWeek(bookingWeek);
          }
        },
        (error) => {
          console.log(error);
          setCustomShowErrorMessage(error);
          setRegistrationQuestionEquipment(bookingEquipment);
          setRegistrationQuestionWeek(bookingWeek);
        }
      )
      .finally(() => {
        setShowSpinner(false);
      });
  };

  function isInFuture(event: ISession) {
    let isInFuture = event.start.getTime() > new Date().getTime();
    return isInFuture && (event.slotsAvailable > 0 || event.waitListAvailable);
  }

  const hasUpdateToSave =
    registrationQuestionWeek !== bookingWeek || registrationQuestionEquipment !== bookingEquipment;

  return (
    <div>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal
        className={styles.modal}
        open={!!selectedSession}
        onClose={onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={!!selectedSession}>
          <div className={styles.paper}>
            <IconButton className={styles.closeButton} aria-label="close" onClick={onModalClose}>
              <Clear />
            </IconButton>
            <h1>Your booking details</h1>

            <div>
              {selectedAppointment.type} with{' '}
              <Chip label={selectedAppointment.calendar} size="small" /> on{' '}
              <Chip
                size="small"
                label={moment(selectedAppointment.dateTime).format('dddd MMMM Do YYYY')}
              />{' '}
              at <Chip label={moment(selectedAppointment.dateTime).format('LT')} size="small" />
            </div>
            {selectedAppointment.positionOnWaitlistQueue && (
              <div>
                <Chip size="small" label={'#' + selectedAppointment.positionOnWaitlistQueue} /> in
                the waitlist
              </div>
            )}
            {selectedSession.weekAndEquipmentRegistrationRequired && (
              <>
                <FormControl component="fieldset" fullWidth>
                  <Select
                    disabled={!isInFuture(selectedSession)}
                    className={styles.select}
                    variant="outlined"
                    value={registrationQuestionWeek}
                    displayEmpty
                    fullWidth
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setRegistrationQuestionWeek(event.target.value as string);
                    }}>
                    <MenuItem value="" disabled>
                      <span className={styles.inputLabel}>
                        {' '}
                        Select week you'll be teaching this session
                      </span>
                    </MenuItem>

                    <MenuItem value="0">First Workshop</MenuItem>

                    {Array.from({ length: 20 }, (_, i) => i + 1).map((i) => (
                      <MenuItem key={i} value={i}>
                        Week {i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl component="fieldset" fullWidth>
                  <Select
                    disabled={!isInFuture(selectedSession)}
                    className={styles.select}
                    variant="outlined"
                    value={registrationQuestionEquipment}
                    displayEmpty
                    fullWidth={true}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setRegistrationQuestionEquipment(event.target.value as string);
                    }}>
                    <MenuItem value="" disabled>
                      <span className={styles.inputLabel}>
                        {' '}
                        Select equipment you will have available this session
                      </span>
                    </MenuItem>
                    <MenuItem value={'Mat'}>Mat only</MenuItem>
                    <MenuItem value={'Reformer'}>Reformer only</MenuItem>
                    <MenuItem value={'Mat/Reformer'}>Mat & Reformer</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}

            <FormControl component="fieldset">
              <Box mt={4}>
                <Box component="span" mr={2}>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={onModalClose}
                    startIcon={<ArrowBack />}>
                    Back
                  </Button>
                </Box>
                <Box component="span" mr={2}>
                  <Button
                    disabled={!isInFuture(selectedSession)}
                    variant="contained"
                    size="medium"
                    onClick={() => setOpenCancelDialog(true)}
                    startIcon={<Delete />}>
                    Cancel Booking
                  </Button>
                </Box>
                {bookingRequiresWeekAndEquipmentRegistration && (
                  <>
                    <Button
                      variant="contained"
                      size="medium"
                      hidden={!isInFuture(selectedSession)}
                      disabled={!hasUpdateToSave}
                      startIcon={<Save />}
                      onClick={updateAppointment}>
                      Update
                    </Button>
                  </>
                )}
                <Box component="span" mr={2}>
                  <Button
                    variant="contained"
                    size="medium"
                    className={styles.joinLiveButton}
                    onClick={() => {
                      if (urlRegex)
                        window.open(selectedAppointment.location.match(urlRegex!)?.pop(), '_blank');
                      else window.open(selectedAppointment.location, '_blank');
                    }}
                    startIcon={<Videocam />}>
                    Join Live
                  </Button>
                </Box>
              </Box>
            </FormControl>
          </div>
        </Fade>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSuccessMessage != null}
        onClose={() => setShowSuccessMessage(null)}
        autoHideDuration={5000}>
        <Alert severity="success">{showSuccessMessage}</Alert>
      </Snackbar>

      <Snackbar
        open={showCustomErrorMessage != null}
        onClose={() => setCustomShowErrorMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={5000}>
        <Alert severity="error">{showCustomErrorMessage}</Alert>
      </Snackbar>

      <Dialog open={openCancelDialog}>
        <DialogTitle id="alert-dialog-cancel">
          {selectedAppointment.isOnWaitlist && (
            <>{`Are you sure you want to remove yourself from the waitlist for this session?`}</>
          )}
          {!selectedAppointment.isOnWaitlist && (
            <>{`Are you sure you want to cancel this booking?`}</>
          )}
        </DialogTitle>
        <DialogContent>
          <div>
            <p>
              <span className={styles.detailsLable}>Session:</span> {selectedAppointment?.type}
            </p>
            <p>
              <span className={styles.detailsLable}>Trainer: </span> {selectedAppointment?.calendar}
            </p>
            <p>
              <span className={styles.detailsLable}>Time: </span>
              {moment(selectedAppointment?.dateTime)
                .tz(userTimezone)
                .format('dddd, MMMM Do YYYY h:mm a')}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={styles.confirmCancelButton}
            color="primary"
            autoFocus
            onClick={() => setOpenCancelDialog(false)}>
            Back
          </Button>
          <Button
            color="primary"
            className={styles.confirmCancelButton}
            onClick={() => {
              if (selectedAppointment) {
                cancelAppointment();
              }
            }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
